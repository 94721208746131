import React, { useState } from 'react';
import { Input, Button, Typography, Form, message, Alert, Modal } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { CloseOutlined, CheckCircleOutlined } from '@ant-design/icons';
import api from '../../services/api';
import './B2BIntro.css';

const { Title, Text } = Typography;

const B2BIntro = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      setErrorMessage(null);

      const response = await api.post('/b2b-leads', {
        email: values.email.toLowerCase()
      });

      if (response.success) {
        form.resetFields();
        setShowSuccess(true);
        // Auto redirect after 3 seconds
        setTimeout(() => {
          setShowSuccess(false);
          navigate('/');
        }, 3000);
      }
    } catch (error) {
      console.error('B2B lead submission error:', error);
      const errorMsg = error.message || 'Failed to submit. Please try again.';
      setErrorMessage(errorMsg);
      message.error(errorMsg);
    } finally {
      setLoading(false);
    }
  };

  const SuccessModal = () => (
    <Modal
      visible={showSuccess}
      footer={null}
      closable={false}
      centered
      width={400}
      className="success-modal"
    >
      <div style={{ textAlign: 'center', padding: '20px 0' }}>
        <CheckCircleOutlined style={{ fontSize: 48, color: '#52c41a', marginBottom: 16 }} />
        <Title level={3}>Thank You!</Title>
        <Text>
          We've received your interest in our B2B solution. We'll be in touch soon with more details.
        </Text>
        <div style={{ marginTop: 24 }}>
          <Text type="secondary">Redirecting to home page...</Text>
        </div>
      </div>
    </Modal>
  );

  return (
    <div className="b2b-container">
      <Button 
        type="text"
        icon={<CloseOutlined />}
        onClick={() => navigate('/')}
        className="close-button1"
      />

      <Link to="/" className="logo-container">
        <img 
          src="/voxi-logo.png" 
          alt="Voxi Logo"
          className="logo"
        />
      </Link>

      <div className="content">
        <div className="text-center">
          <Text className="subtitle">AI-driven solutions for</Text>
          <div className="features">
            <Text className="feature">telemarketing</Text>
            <Text className="separator">,</Text>
            <Text className="feature">surveys</Text>
            <Text className="separator">, and</Text>
            <Text className="feature">customer service</Text>
            <Text className="separator">.</Text>
          </div>
        </div>

        <Title level={1} className="main-title">
          Transform Your Calls<br />with AI Agents.
        </Title>

        <Text className="description">
          Automate your outbound calls with AI agents that sound natural and handle 
          conversations seamlessly. Launch thousands of calls simultaneously with a 
          single prompt, saving time, effort, and up to 80% of your costs. Streamline your 
          operations and scale effortlessly while delivering a human-like experience to 
          your customers.
        </Text>

        <div className="signup-section">
          <Text className="signup-text">
            Sign up for early access to the B2B version
          </Text>

          {errorMessage && (
            <Alert
              message={errorMessage}
              type="error"
              showIcon
              closable
              onClose={() => setErrorMessage(null)}
              style={{ marginBottom: 16 }}
            />
          )}
          
          <Form 
            form={form}
            onFinish={handleSubmit}
            className="signup-form"
          >
            <Form.Item
              name="email"
              rules={[
                { required: true, message: 'Please enter your work email' },
                { type: 'email', message: 'Please enter a valid email' },
                { 
                  pattern: /^[A-Z0-9._%+-]+@(?!gmail.com)(?!yahoo.com)(?!hotmail.com)(?!outlook.com)[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please use your work email'
                }
              ]}
              help="Use your company email (not gmail, yahoo, etc.)"
            >
              <Input
                placeholder="Enter work email"
                className="email-input"
                size="large"
                disabled={loading}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                loading={loading}
                className="signup-button"
                size="large"
                block
              >
                Get Early Access
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      <SuccessModal />
    </div>
  );
};

export default B2BIntro;