import React, { createContext, useState, useContext, useEffect } from 'react';
import { creditAPI } from '../services/api';
import { useAuth } from './AuthContext';

const CreditContext = createContext({});

export const useCredits = () => useContext(CreditContext);

export const CreditProvider = ({ children }) => {
  const [balance, setBalance] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { user } = useAuth();

  // Fetch initial balance
  const fetchBalance = async () => {
    try {
      setLoading(true);
      console.log('FETCHING BALANCE...');  // ADD THIS
      const data = await creditAPI.getBalance();
      console.log('GOT BALANCE:', data);   // ADD THIS
      if (data && typeof data.credits === 'number') {
        console.log('SETTING NEW BALANCE:', data.credits); // ADD THIS
        setBalance(data.credits);
      } else {
        throw new Error('Invalid balance data');
      }
      setError(null);
    } catch (err) {
      console.error('Error fetching credit balance:', err);
      setError('Unable to fetch credit balance');
    } finally {
      setLoading(false);
    }
  };

  // Purchase credits
  const purchaseCredits = async (amount) => {
    try {
      setLoading(true);
      const response = await creditAPI.purchaseCredits(amount);

      // Update balance based on the response structure
      if (response.success) {
        if (typeof response.credits === 'number') {
          setBalance(response.credits);
        } else {
          throw new Error('Invalid credits data');
        }
        return response;
      } else {
        throw new Error(response.message || 'Failed to purchase credits');
      }
    } catch (err) {
      setError(err.message);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  // Update balance from WebSocket messages
  const updateBalanceFromWsMessage = (message) => {
    switch (message.type) {
      case 'credit_status':
        setBalance(message.data.remainingCredits);
        break;
      case 'credit_deduction':
        setBalance(message.data.newBalance);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    // Fetch balance when component mounts and when user changes
    if (user !== undefined) {
      fetchBalance();
    }
  }, [user]); // Depend on user to fetch balance when it becomes available

  const value = {
    balance,
    loading,
    error,
    purchaseCredits,
    refreshBalance: fetchBalance,
    updateBalanceFromWsMessage,
  };

  return (
    <CreditContext.Provider value={value}>
      {children}
    </CreditContext.Provider>
  );
};