import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  Button,
  Divider,
  Spin,
} from 'antd';
import { GoogleOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/AuthContext';
import EmailVerification from './EmailVerification';
import AuthForm from './AuthForm';
import './AuthPage.css';

const { Title, Text } = Typography;

const AuthPage = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [registeredEmail, setRegisteredEmail] = useState(null);
  const navigate = useNavigate();
  const { signIn, signUp, signInWithGoogle, user, loading: authLoading } = useAuth();

  useEffect(() => {
    if (user?.emailVerified && !authLoading) {
      navigate('/');
    }
  }, [user, authLoading, navigate]);

  const handleSubmit = async (values) => {
    try {
      setError(null);
      setLoading(true);
      
      if (isLogin) {
        await signIn(values.email, values.password);
        navigate('/');
      } else {
        await signUp(values.email, values.password);
        setRegisteredEmail(values.email);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      setError(null);
      setLoading(true);
      await signInWithGoogle();
      navigate('/');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  if (authLoading) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const renderAuthContent = () => {
    if (registeredEmail) {
      return (
        <EmailVerification 
          email={registeredEmail}
          onBackToSignIn={() => {
            setRegisteredEmail(null);
            setIsLogin(true);
          }}
        />
      );
    }

    return (
      <>
        <Button
          icon={<GoogleOutlined />}
          className="google-button"
          onClick={handleGoogleSignIn}
          loading={loading}
        >
          Continue with Google
        </Button>

        <Divider className="auth-divider">or continue with email</Divider>

        <AuthForm 
          isLogin={isLogin}
          onSubmit={handleSubmit}
          loading={loading}
          error={error}
          onToggleMode={() => setIsLogin(!isLogin)}
        />
      </>
    );
  };

  return (
    <div className="auth-page">
      <div className="auth-content">
        {/* Logo */}
        <div className="auth-logo">
          <img 
            src="/voxi-logo.png"
            alt="Voxi Logo"
          />
        </div>

        {/* Hero Text */}
        <div className="auth-hero">
          <Title level={1} className="auth-hero-title">
            Meet the First AI Agent That Calls for You.
          </Title>
          <Text className="auth-hero-subtitle">
            Effortless Conversations, Powered by AI.
          </Text>
        </div>

        {/* Auth Form Container */}
        <div className="auth-form-container">
          <Title level={2} className="auth-form-title">
            {isLogin ? 'Welcome Back' : 'Create Account'}
          </Title>
          <Text type="secondary" className="auth-form-subtitle">
            {isLogin 
              ? 'Enter your credentials to access your account'
              : 'Get started with your free account today'}
          </Text>

          {renderAuthContent()}
        </div>
      </div>
    </div>
  );
};

export default AuthPage;