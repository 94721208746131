import React, { useState } from 'react';
import { Layout, Button, Typography, Space, Badge, Avatar, Dropdown, Spin, Drawer, Menu } from 'antd'; // Added Drawer, Menu
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import {
  PhoneOutlined,
  HistoryOutlined,
  CreditCardOutlined,
  UserOutlined,
  RocketOutlined,
  LogoutOutlined,
  CloseOutlined,
  MenuOutlined,  // Added this icon
  ExperimentOutlined
} from '@ant-design/icons';
import { useAuth } from '../../contexts/AuthContext';
import NewCall from '../calls/NewCall';
import CallHistory from '../calls/CallHistory';
import CreditsView from '../credits/CreditsView';
import { useCredits } from '../../contexts/CreditContext';
import './MainLayout.css';

const { Header, Content } = Layout;
const { Text } = Typography;

const MainLayout = () => {
  const [credits] = useState(10);
  const navigate = useNavigate();
  const location = useLocation();
  const { user, loading, logout } = useAuth();
  const { balance, loading: creditsLoading } = useCredits();
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  // Added new state for mobile menu
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  if (loading) {
    return (
      <div style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/auth" replace />;
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/auth');
    } catch (error) {
      console.error('Failed to log out:', error);
    }
  };

  const userMenuItems = {
    items: [
      {
        key: 'experimental',
        icon: <ExperimentOutlined />,
        label: 'Experimental',
        onClick: () => navigate('/experimental'),
      },
      {
        type: 'divider'  // Add a divider between options
      },
      {
        key: 'logout',
        icon: <LogoutOutlined />,
        label: 'Sign Out',
        onClick: handleLogout,
      },
    ]
  };

  const renderContent = () => {
    switch (location.pathname) {
      case '/':
        return <NewCall />;
      case '/history':
        return <CallHistory />;
      case '/credits':
        return <CreditsView />;
      case '/experimental':
        return <div>Experimental Page Coming Soon</div>;  // Placeholder for now
      default:
        return <NewCall />;
    }
  };

  const isCurrentPath = (path) => location.pathname === path;

  // Added mobile menu component
  const MobileMenu = () => (
    <Menu mode="vertical" className="mobile-menu">
      <Menu.Item 
        key="newcall" 
        icon={<PhoneOutlined />} 
        onClick={() => {
          navigate('/');
          setMobileMenuVisible(false);
        }}
      >
        New Call
      </Menu.Item>
      <Menu.Item 
        key="history" 
        icon={<HistoryOutlined />}
        onClick={() => {
          navigate('/history');
          setMobileMenuVisible(false);
        }}
      >
        Call History
      </Menu.Item>
      <Menu.Item 
        key="credits" 
        icon={<CreditCardOutlined />}
        onClick={() => {
          navigate('/credits');
          setMobileMenuVisible(false);
        }}
      >
        Buy Credits
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item disabled className="mobile-credits">
        Credits: ${balance || 0}
      </Menu.Item>
      <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={handleLogout}>
        Sign Out
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout className="app-container">
      {showAnnouncement && (
        <div className="announcement-strip">
          <div className="announcement-content">
            <RocketOutlined className="announcement-icon" />
            {/* Desktop Announcement */}
            <Text className="announcement-text desktop-announcement">
              <span className="announcement-highlight">Enterprise Solution Coming Soon:</span>{' '}
              <span className="announcement-body">
                Launch massive call campaigns instantly. Execute 100+ simultaneous calls with a single prompt.
              </span>
            </Text>
            {/* Mobile Announcement */}
            <Text className="announcement-text mobile-announcement">
              <span className="announcement-body">
                Coming soon: AI calling at scale
              </span>
            </Text>
            <div className="announcement-actions">
              <Button 
                type="text" 
                className="interested-button"
                onClick={() => navigate('/b2b')}
              >
                Interested?
              </Button>
              <Button 
                type="text" 
                icon={<CloseOutlined />} 
                className="close-button"
                onClick={() => setShowAnnouncement(false)}
              />
            </div>
          </div>
        </div>
      )}

      <Header className="header-container">
        <div className="header-content">
          <div className="header-logo">
            <img 
              src="/voxi-logo.png" 
              alt="Voxi Logo" 
              className="logo-image"
            />
          </div>

          {/* Desktop Menu */}
          <div className="nav-buttons hide-mobile">
            <div className="segmented-container">
              <Button
                className={`nav-button ${isCurrentPath('/') ? 'active' : ''}`}
                onClick={() => navigate('/')}
                icon={<PhoneOutlined />}
              >
                New Call
              </Button>
              <Button
                className={`nav-button ${isCurrentPath('/history') ? 'active' : ''}`}
                onClick={() => navigate('/history')}
                icon={<HistoryOutlined />}
              >
                Call History
              </Button>
              <Button
                className={`nav-button ${isCurrentPath('/credits') ? 'active' : ''}`}
                onClick={() => navigate('/credits')}
                icon={<CreditCardOutlined />}
              >
                Buy Credits
              </Button>
            </div>
          </div>

          <div className="header-actions hide-mobile">
            <Space size="large">
              <Badge count={balance ? Math.floor(balance) : 0} offset={[-5, 5]}>
                <Button 
                  className="nav-button"
                  icon={<CreditCardOutlined />}
                  onClick={() => navigate('/credits')}
                >
                  Credits
                </Button>
              </Badge>
              <Dropdown menu={userMenuItems} placement="bottomRight">
                <Avatar icon={<UserOutlined />} className="user-avatar" />
              </Dropdown>
            </Space>
          </div>

          {/* Mobile Menu Button */}
          <Button
            className="mobile-menu-button show-mobile"
            icon={<MenuOutlined />}
            onClick={() => setMobileMenuVisible(true)}
          />
        </div>
      </Header>

      {/* Mobile Menu Drawer */}
      <Drawer
        title="Menu"
        placement="right"
        onClose={() => setMobileMenuVisible(false)}
        open={mobileMenuVisible}
        className="mobile-drawer"
      >
        <MobileMenu />
      </Drawer>

      <Content className="content-container">
        {renderContent()}
      </Content>
    </Layout>
  );
};

export default MainLayout;