import React from 'react';
import { Modal, Typography, List, Card, Row, Col, Alert } from 'antd';
import {
  GlobalOutlined,
  PhoneOutlined,
  DollarOutlined,
  InfoCircleOutlined,
  ClockCircleOutlined,
  MessageOutlined,
  BulbOutlined
} from '@ant-design/icons';
import './VoxiGuideModal.css';

const { Title, Text, Paragraph } = Typography;

const FeatureCard = ({ icon, title, children }) => (
  <Card className="feature-card">
    <div className="feature-card-header">
      {icon}
      <Title level={5}>{title}</Title>
    </div>
    {children}
  </Card>
);

const ListItemWithIcon = ({ text }) => (
  <span className="list-item-content">
    <InfoCircleOutlined className="list-icon" />
    <span>{text}</span>
  </span>
);

const VoxiGuideModal = ({ open, onClose }) => {
  return (
    <Modal
      title={null}
      open={open}
      onCancel={onClose}
      footer={null}
      width={900}
      className="voxi-guide-modal"
    >
      <div className="guide-content">
        <div className="guide-header">
          <Title level={2}>How Our System Works</Title>
        </div>

        <Alert
          message="Important Information About Calls"
          description={
            <ul style={{ marginBottom: 0 }}>
              <li>All calls currently have a 5-minute time limit and will automatically end after this duration</li>
              <li>You will be charged for the call duration even if the recipient doesn't answer (voicemail attempts included)</li>
              <li>The AI agent will attempt to leave a voicemail if the call isn't answered (beta feature, still being optimized)</li>
            </ul>
          }
          type="warning"
          showIcon
        />

        <section className="system-overview">
          <FeatureCard
            icon={<PhoneOutlined className="feature-icon" />}
            title="System Overview"
          >
            <Paragraph>
              Our platform uses advanced AI technology to make calls on your behalf through US-based phone numbers. You can make calls to most countries worldwide, with different rates applying to international destinations.
            </Paragraph>
          </FeatureCard>
        </section>

        <section className="instructions-section">
          <Row>
            <Col span={24}>
              <FeatureCard
                icon={<BulbOutlined className="feature-icon" />}
                title="Writing Effective Instructions"
              >
                <Alert
                  message="Your instructions directly impact call duration and costs"
                  type="info"
                  showIcon
                  style={{ marginBottom: 16 }}
                />
                <List
                  className="feature-list"
                  dataSource={[
                    'Be extremely specific about your objective to minimize unnecessary call time',
                    'Include clear success/completion criteria',
                    'Specify any information you need collected',
                    'Include any specific questions that need answers'
                  ]}
                  renderItem={text => (
                    <List.Item>
                      <ListItemWithIcon text={text} />
                    </List.Item>
                  )}
                />
                <Paragraph type="secondary">
                  Example: Instead of "Call to ask about their services", use "Call to check if they offer weekend appointments, their hourly rate, and earliest available slot"
                </Paragraph>
              </FeatureCard>
            </Col>
          </Row>
        </section>

        <section className="duration-voicemail-section">
          <Row gutter={[24, 24]}>
            <Col span={12}>
              <FeatureCard
                icon={<ClockCircleOutlined className="feature-icon" />}
                title="Call Duration & Limits"
              >
                <List
                  className="feature-list"
                  dataSource={[
                    'All calls have a strict 5-minute limit',
                    'Calls automatically end at 5 minutes',
                    'Plan your instructions accordingly',
                    'Multiple shorter calls may be better than rushing a long call'
                  ]}
                  renderItem={text => (
                    <List.Item>
                      <ListItemWithIcon text={text} />
                    </List.Item>
                  )}
                />
              </FeatureCard>
            </Col>

            <Col span={12}>
              <FeatureCard
                icon={<MessageOutlined className="feature-icon" />}
                title="Voicemail Handling (Beta)"
              >
                <Paragraph>
                  If a call isn't answered, our AI agent will attempt to leave a voicemail. Please note:
                </Paragraph>
                <List
                  className="feature-list"
                  dataSource={[
                    'Voicemail feature is currently in beta',
                    'You will be charged for the attempt',
                    'Success rates vary by voicemail system',
                    'We are actively improving this feature'
                  ]}
                  renderItem={text => (
                    <List.Item>
                      <ListItemWithIcon text={text} />
                    </List.Item>
                  )}
                />
              </FeatureCard>
            </Col>
          </Row>
        </section>

        <section className="pricing-section">
          <FeatureCard
            icon={<DollarOutlined className="feature-icon" />}
            title="Pricing & Billing"
          >
            <List
              className="feature-list"
              dataSource={[
                'Charges apply from call initiation to end, including unanswered calls',
                'Different rates for domestic and international calls',
                'Separate pricing for landline and mobile numbers',
                'Always use international format (+[country][number]) for accurate pricing'
              ]}
              renderItem={text => (
                <List.Item>
                  <ListItemWithIcon text={text} />
                </List.Item>
              )}
            />
          </FeatureCard>
        </section>

        <section className="international-section">
          <FeatureCard
            icon={<GlobalOutlined className="feature-icon" />}
            title="International Calling"
          >
            <Paragraph>
              While calls currently originate from US numbers, you can make calls to most international destinations. Each country has specific rates, and international calls will be billed at their respective rates.
            </Paragraph>
          </FeatureCard>
        </section>
      </div>
    </Modal>
  );
};

export default VoxiGuideModal;