import React from 'react';
import { CheckOutlined } from '@ant-design/icons';

const StepIndicator = ({ currentStep }) => {
  const steps = [
    { key: 0, title: 'Setup' },
    { key: 1, title: 'In Progress' },
    { key: 2, title: 'Summary' }
  ];

  return (
    <div className="step-indicator">
      {steps.map((step, index) => {
        const isActive = currentStep === step.key;
        const isComplete = currentStep > step.key;
        let statusClass = isActive ? 'active' : isComplete ? 'complete' : '';

        return (
          <React.Fragment key={step.key}>
            <div className={`step ${statusClass}`}>
              <div className="step-circle">
                {isComplete ? <CheckOutlined /> : step.key + 1}
              </div>
              <div className="step-title">{step.title}</div>
              {isActive && <div className="step-pulse" />}
            </div>
            {index < steps.length - 1 && (
              <div className={`step-line ${isComplete ? 'complete' : ''}`} />
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default StepIndicator;