import React from 'react';
import { Typography } from 'antd';

const { Text } = Typography;

const CallTitle = () => {
  return (
    <div style={{ 
      padding: '12px',
      textAlign: 'center',
      backgroundColor: 'transparent'
    }}>
      <Text type="secondary" style={{ 
        fontSize: '14px',
        backgroundColor: '#F5F5F5',
        padding: '4px 8px',
        borderRadius: '4px'
      }}>
        Meet the only AI agent that makes calls as naturally as a human, with perfect task completion
      </Text>
    </div>
  );
};

export default CallTitle;