import React from 'react';
import { ConfigProvider, App as AntApp } from 'antd';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { CreditProvider } from './contexts/CreditContext';
import MainLayout from './components/layouts/MainLayout';
import AuthPage from './components/auth/AuthPage';
import B2BIntro from './components/b2b/B2BIntro';
import TestCallMonitor from './components/test/TestCallMonitor.js';
import TestWebSocket from './components/test/TestWebSocket';
import ExperimentalPage from './components/experimental/ExperimentalPage';
import ExperimentalLayout from './components/experimental/ExperimentalLayout';

const AppContent = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#1890ff',
        },
      }}
    >
      <Router>
        <AuthProvider>
          <CreditProvider>
            <Routes>
              <Route 
                path="/auth" 
                element={<AuthPage />} 
              />
              <Route 
                path="/b2b"
                element={<B2BIntro />}
              />
              <Route 
                path="/test-websocket"
                element={<TestWebSocket />} 
              />
              <Route 
                path="/test-call-monitor"
                element={<TestCallMonitor />}
              />
              <Route 
                path="/experimental" 
                element={
                  <ExperimentalLayout>
                    <ExperimentalPage />
                  </ExperimentalLayout>
                } 
              />
              <Route 
                path="/*" 
                element={<MainLayout />}
              />
            </Routes>
          </CreditProvider>
        </AuthProvider>
      </Router>
    </ConfigProvider>
  );
};

function App() {
  return (
    <AntApp>
      <AppContent />
    </AntApp>
  );
}

export default App;