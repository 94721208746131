import React from 'react';
import { Card, Row, Col, Typography, Button, Tag, Space } from 'antd';
import { PhoneOutlined, CheckCircleOutlined, ClockCircleOutlined } from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;

const SummaryStep = ({ summary, onNewCall }) => {
  if (!summary) return null;
  
  // Handle both summary formats we see in the logs
  const summaryData = summary.structuredSummary || summary.structured;
  
  console.log('Summary received in SummaryStep:', summary); // Let's keep this log to verify data
  console.log('Parsed summaryData:', summaryData);

  return (
    <Space direction="vertical" size="large" className="w-full">
      <Card>
        <Row align="middle" justify="space-between">
          <Space>
            <CheckCircleOutlined style={{ fontSize: '24px', color: '#52c41a' }} />
            <Title level={4} style={{ margin: 0 }}>Call Completed</Title>
          </Space>
          <Tag icon={<ClockCircleOutlined />} color="blue">
            Status: {summary.status || 'completed'}
          </Tag>
        </Row>
      </Card>

      {summaryData && (
        <Card>
          {summaryData.mainObjective && (
            <div style={{ marginBottom: '1rem' }}>
              <Title level={5}>Objective</Title>
              <Paragraph>{summaryData.mainObjective}</Paragraph>
            </div>
          )}

          {summaryData.outcome && (
            <div style={{ marginBottom: '1rem' }}>
              <Title level={5}>Outcome</Title>
              <Paragraph>{summaryData.outcome}</Paragraph>
            </div>
          )}

          {Array.isArray(summaryData.keyPoints) && summaryData.keyPoints.length > 0 && (
            <div style={{ marginBottom: '1rem' }}>
              <Title level={5}>Key Points</Title>
              <ul>
                {summaryData.keyPoints.map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </ul>
            </div>
          )}

          {Array.isArray(summary.nextSteps) && summary.nextSteps.length > 0 && (
            <div style={{ marginBottom: '1rem' }}>
              <Title level={5}>Next Steps</Title>
              <ul>
                {summary.nextSteps.map((step, index) => (
                  <li key={index}>{step}</li>
                ))}
              </ul>
            </div>
          )}
        </Card>
      )}

      {/* Raw summary as fallback if no structured data */}
      {!summaryData && summary.summary && (
        <Card>
          <Paragraph>{summary.summary}</Paragraph>
        </Card>
      )}

      <div style={{ textAlign: 'center', padding: '16px 0' }}>
        <Button 
          type="primary"
          size="large"
          icon={<PhoneOutlined />}
          onClick={onNewCall}
        >
          Start Another Call
        </Button>
      </div>
    </Space>
  );
};

export default SummaryStep;