// src/components/test/TestWebSocket.js
import React, { useEffect, useState } from 'react';
import { Card, Button, Typography, List } from 'antd';

const { Title, Text } = Typography;

// Update your TestWebSocket.js
const TestWebSocket = () => {
  const [status, setStatus] = useState('Disconnected');
  const [messages, setMessages] = useState([]);
  const [ws, setWs] = useState(null);

  useEffect(() => {
      let socket = null;
      let reconnectTimer = null;

      const connect = () => {
          try {
              console.log('[WS] Attempting connection...');
              socket = new WebSocket('wss://aigent-backend.onrender.com/ws/test');

              socket.onopen = () => {
                  console.log('[WS] Connected');
                  setStatus('Connected');
                  setWs(socket);
              };

              socket.onclose = (event) => {
                  console.log('[WS] Disconnected:', event);
                  setStatus(`Disconnected (${event.code})`);
                  setWs(null);
                  
                  // Try to reconnect after 5 seconds
                  reconnectTimer = setTimeout(connect, 5000);
              };

              socket.onerror = (error) => {
                  console.error('[WS] Error:', error);
              };

              socket.onmessage = (event) => {
                  try {
                      const message = JSON.parse(event.data);
                      console.log('[WS] Received:', message);
                      setMessages(prev => [...prev, `Received: ${JSON.stringify(message)}`]);
                  } catch (err) {
                      console.error('[WS] Message parse error:', err);
                  }
              };

          } catch (err) {
              console.error('[WS] Setup error:', err);
              reconnectTimer = setTimeout(connect, 5000);
          }
      };

      connect();

      return () => {
          console.log('[WS] Cleanup');
          clearTimeout(reconnectTimer);
          if (socket) {
              socket.close();
          }
      };
  }, []);

  const sendTestMessage = () => {
      if (ws?.readyState === WebSocket.OPEN) {
          const message = `Test message ${new Date().toISOString()}`;
          console.log('[WS] Sending:', message);
          ws.send(message);
          setMessages(prev => [...prev, `Sent: ${message}`]);
      } else {
          console.log('[WS] Cannot send - not connected');
      }
  };

  return (
      <Card title="WebSocket Test" style={{ maxWidth: 600, margin: '20px auto' }}>
          <div style={{ marginBottom: 20 }}>
              <Text strong>Status: </Text>
              <Text type={status === 'Connected' ? 'success' : 'danger'}>{status}</Text>
          </div>

          <Button 
              type="primary"
              onClick={sendTestMessage}
              disabled={!ws || ws.readyState !== WebSocket.OPEN}
              style={{ marginBottom: 20 }}
          >
              Send Test Message
          </Button>

          <div>
              <Title level={5}>Messages ({messages.length}):</Title>
              <List
                  size="small"
                  bordered
                  dataSource={messages}
                  renderItem={(msg, idx) => (
                      <List.Item key={idx}>
                          {msg}
                      </List.Item>
                  )}
              />
          </div>
      </Card>
  );
};

export default TestWebSocket;