import React, { useState } from 'react';
import { 
  Card, 
  Table, 
  Typography, 
  Tag, 
  Space, 
  Button,
  Input,
  DatePicker
} from 'antd';
import {
  CheckCircleOutlined,
  SearchOutlined,
  CalendarOutlined,
  PhoneOutlined,
  ClockCircleOutlined,
  MessageOutlined
} from '@ant-design/icons';

const { Title, Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;

const ResultsManager = ({ results = [] }) => {
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState(null);

  const columns = [
    {
      title: 'Contact',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <Space direction="vertical" size="small">
          <Text strong>{text}</Text>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            <PhoneOutlined style={{ marginRight: 8 }} />
            {record.phone}
          </Text>
        </Space>
      ),
      filteredValue: searchText ? [searchText] : null,
      onFilter: (value, record) => 
        record.name.toLowerCase().includes(value.toLowerCase()) ||
        record.phone.includes(value)
    },
    {
      title: 'Property',
      dataIndex: 'property',
      key: 'property',
      ellipsis: true
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      render: (duration) => (
        <Space>
          <ClockCircleOutlined />
          <Text>{formatDuration(duration)}</Text>
        </Space>
      )
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        const success = isCallSuccessful(record);
        return (
          <Tag icon={success ? <CheckCircleOutlined /> : null} color={success ? 'success' : 'warning'}>
            {success ? 'Successful' : 'Incomplete'}
          </Tag>
        );
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
        <Button 
          type="link" 
          icon={<MessageOutlined />}
          onClick={() => handleExpandRow(record)}
        >
          View Details
        </Button>
      ),
    }
  ];

  const formatDuration = (seconds) => {
    if (!seconds) return '0:00';
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const isCallSuccessful = (record) => {
    const summary = record.summary?.toLowerCase() || '';
    return (
      summary.includes('success') ||
      summary.includes('scheduled') ||
      summary.includes('interested') ||
      summary.includes('agreed')
    );
  };

  const handleExpandRow = (record) => {
    // Toggle expansion logic here if needed
  };

  const filteredResults = results.filter(record => {
    let matchesSearch = true;
    let matchesDate = true;

    if (searchText) {
      matchesSearch = 
        record.name.toLowerCase().includes(searchText.toLowerCase()) ||
        record.phone.includes(searchText) ||
        record.property.toLowerCase().includes(searchText.toLowerCase());
    }

    if (dateRange) {
      const recordDate = new Date(record.timestamp);
      matchesDate = 
        recordDate >= dateRange[0] &&
        recordDate <= dateRange[1];
    }

    return matchesSearch && matchesDate;
  });

  return (
    <Card
      bordered={false}
      style={{
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
      }}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Space 
          style={{ 
            justifyContent: 'space-between', 
            width: '100%',
            background: '#fafafa',
            padding: '16px',
            borderRadius: '8px'
          }}
        >
          <Title level={5} style={{ margin: 0 }}>Campaign Results</Title>
          <Space>
            <RangePicker 
              onChange={setDateRange}
              placeholder={['Start Date', 'End Date']}
              separator="→"
              style={{ borderRadius: '6px' }}
              suffixIcon={<CalendarOutlined />}
            />
            <Input
              placeholder="Search contacts..."
              prefix={<SearchOutlined />}
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              style={{ width: 200, borderRadius: '6px' }}
              allowClear
            />
          </Space>
        </Space>

        <Table
          columns={columns}
          dataSource={filteredResults}
          expandable={{
            expandedRowRender: (record) => (
              <Space direction="vertical" style={{ width: '100%', padding: '20px' }}>
                <Card 
                  size="small" 
                  bordered={false}
                  style={{
                    background: '#fafafa',
                    borderRadius: '8px'
                  }}
                >
                  <Space direction="vertical" style={{ width: '100%' }} size="large">
                    <div>
                    <Title level={5}>Call Transcript</Title>
                    <Paragraph style={{ whiteSpace: 'pre-line' }}>
                      {record.conversation || 'No conversation transcript available'}
                    </Paragraph>
                    </div>
                    <div>
                      <Title level={5}>Call Summary</Title>
                      <Paragraph>
                        {record.summary || 'No summary available'}
                      </Paragraph>
                    </div>
                    {record.nextSteps && (
                      <div>
                        <Title level={5}>Next Steps</Title>
                        <Paragraph>
                          {record.nextSteps}
                        </Paragraph>
                      </div>
                    )}
                  </Space>
                </Card>
              </Space>
            ),
            rowExpandable: record => record.conversation || record.summary,
          }}
          pagination={{
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} calls`
          }}
          style={{ 
            background: 'white',
            borderRadius: '8px'
          }}
        />
      </Space>
    </Card>
  );
};

export default ResultsManager;