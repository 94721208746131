import React, { useState } from 'react';
import { Form, Alert, message } from 'antd';
import { callsAPI } from '../../../services/api';
import CallTitle from './components/CallTitle';
import StepIndicator from './components/StepIndicator';
import SetupStep from './components/SetupStep';
import ProgressStep from './components/ProgressStep';
import SummaryStep from './components/SummaryStep';
import './components/styles.css';

const NewCall = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [callData, setCallData] = useState(null);
  const [error, setError] = useState(null);
  const [callSummary, setCallSummary] = useState(null);

  const handleSubmit = async (result) => {
    try {
      setIsLoading(true);
      setError(null);

      if (result.callSid) {
        setCallData(result);
        setCurrentStep(1);
      } else {
        throw new Error('No call ID received from server');
      }
    } catch (err) {
      console.error('Call initiation error:', err);
      setError(err.message || 'Failed to initiate call. Please try again.');
      message.error('Failed to start call. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCallComplete = (callSid, summaryData) => {
    console.log('Call completed, summary received:', summaryData);
    setCallSummary(summaryData);
    setCurrentStep(2);
  };

  const resetCall = () => {
    setCurrentStep(0);
    setCallData(null);
    setCallSummary(null);
    setError(null);
    form.resetFields();
  };

  const renderCurrentStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <SetupStep 
            form={form}
            onSubmit={handleSubmit}
            isLoading={isLoading}
          />
        );
      
      case 1:
        return (
          <ProgressStep 
            phoneNumber={form.getFieldValue('phoneNumber')}
            callData={callData}
            onCallComplete={handleCallComplete}
          />
        );
      
      case 2:
        return (
          <SummaryStep 
            summary={callSummary}
            onNewCall={resetCall}
          />
        );
        
      default:
        return null;
    }
  };

  return (
    <div className="new-call-container">
      <CallTitle />
      
      {error && (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
          className="error-alert"
          closable
          onClose={() => setError(null)}
        />
      )}

      <StepIndicator currentStep={currentStep} />

      <div className="steps-content">
        {renderCurrentStep()}
      </div>
    </div>
  );
};

export default NewCall;