import React, { useState } from 'react';
import { 
  Card, 
  Typography, 
  Upload, 
  Button, 
  Table, 
  Input,
  Space,
  Tag,
  Tooltip,
  message
} from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined
} from '@ant-design/icons';
import Papa from 'papaparse';
import { callsAPI } from '../../services/api';

const { Title, Text, Paragraph } = Typography;
const { TextArea } = Input;
const { Dragger } = Upload;

const CampaignManager = ({ 
  contacts,
  setContacts,
  csvUploaded,
  setCsvUploaded,
  onCallStart,
  onCallComplete 
}) => {
  const [basePrompt, setBasePrompt] = useState('');
  const [processing, setProcessing] = useState(false);
  const [activeBatch, setActiveBatch] = useState([]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Property Address',
      dataIndex: 'property',
      key: 'property',
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => {
        let color = 'default';
        let icon = <ClockCircleOutlined />;
        let text = 'Pending';

        if (record.status === 'completed') {
          color = 'success';
          icon = <CheckCircleOutlined />;
          text = 'Completed';
        } else if (record.status === 'in-progress') {
          color = 'processing';
          icon = <SyncOutlined spin />;
          text = 'In Progress';
        }

        return (
          <Tag icon={icon} color={color}>
            {text}
          </Tag>
        );
      }
    }
  ];

  const processCSV = (file) => {
    return new Promise((resolve, reject) => {
      Papa.parse(file, {
        header: true,
        skipEmptyLines: true,
        complete: (results) => {
          try {
            const processedContacts = [];
            
            results.data.forEach((row, index) => {
              // Extract and clean phone numbers
              const phoneNumbers = row['Landline Numbers']?.split('\n')
                .map(phone => phone.replace(/\s*-\s*Landline/, '').trim())
                .filter(phone => phone && phone !== 'Landline');  // Remove empty and "Landline" only entries
  
              if (phoneNumbers && phoneNumbers.length > 0) {
                // Create a contact entry for each phone number
                phoneNumbers.forEach((phone) => {
                  processedContacts.push({
                    key: `${index}-${phone}`,
                    name: row['Contact Person']?.replace(/\n/g, ' ').trim(),
                    phone: phone,
                    property: row['Address'],
                    status: 'pending'
                  });
                });
              }
            });
  
            console.log('Processed contacts:', processedContacts);
            resolve(processedContacts);
          } catch (error) {
            console.error('CSV Processing error:', error);
            reject(new Error('Error processing CSV: ' + error.message));
          }
        },
        error: (error) => {
          console.error('CSV Parsing error:', error);
          reject(new Error('Error parsing CSV: ' + error.message));
        }
      });
    });
  };

  const handleCsvUpload = async (info) => {
    const { file } = info;
    
    if (file.status !== 'uploading') {
      try {
        setProcessing(true);
        const processedContacts = await processCSV(file.originFileObj);
        setContacts(processedContacts);
        setCsvUploaded(true);
        message.success(`Successfully processed ${processedContacts.length} contacts`);
      } catch (error) {
        message.error(error.message);
      } finally {
        setProcessing(false);
      }
    }
  };

  const customizePrompt = (basePrompt, contact) => {
    return basePrompt
      .replace('{name}', contact.name)
      .replace('{property}', contact.property)
      .replace('{address}', contact.property); // Added alias for property
  };

  const startBatchCalls = async () => {
    if (!basePrompt.trim()) {
      message.error('Please enter a base prompt');
      return;
    }

    const pendingContacts = contacts.filter(c => c.status === 'pending');
    if (pendingContacts.length === 0) {
      message.info('No pending contacts to call');
      return;
    }

    const batchSize = 10;
    const currentBatch = pendingContacts.slice(0, batchSize);
    setActiveBatch(currentBatch);

    // Notify parent of batch start
    onCallStart?.(currentBatch);

    try {
      // Make all calls
      const callPromises = currentBatch.map(async (contact) => {
        const customPrompt = customizePrompt(basePrompt, contact);
        try {
          const result = await callsAPI.makeCall(contact.phone, customPrompt);
          return {
            ...contact,
            callSid: result.callSid,
            result
          };
        } catch (error) {
          console.error(`Call failed for ${contact.name}:`, error);
          return {
            ...contact,
            status: 'failed',
            error: error.message
          };
        }
      });

      // Wait for all calls to complete
      const results = await Promise.all(callPromises);

      // Notify parent of completed calls
      onCallComplete?.(results.filter(r => !r.error));
      message.success(`Completed batch of ${results.length} calls`);
    } catch (error) {
      message.error('Error processing batch: ' + error.message);
    } finally {
      setActiveBatch([]);
    }
  };

  const resetUpload = () => {
    setCsvUploaded(false);
    setContacts([]);
    setActiveBatch([]);
  };

  return (
    <Card
      bordered={false}
      style={{
        borderRadius: '12px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        <div>
          <Title level={5} style={{ marginBottom: '16px' }}>Campaign Prompt</Title>
          <Paragraph type="secondary" style={{ marginBottom: '16px' }}>
            Enter your base prompt below. This will be automatically customized for each contact with their specific details.
            <Tooltip title="Use {name} for contact name and {property} for property address">
              <InfoCircleOutlined style={{ marginLeft: 8 }} />
            </Tooltip>
          </Paragraph>
          <TextArea
            rows={6}
            value={basePrompt}
            onChange={(e) => setBasePrompt(e.target.value)}
            placeholder="Enter your base prompt here. Use {name} and {property} as placeholders..."
            style={{ borderRadius: '8px' }}
          />
        </div>

        {!csvUploaded ? (
          <div>
            <Title level={5} style={{ marginBottom: '16px' }}>Upload Contact List</Title>
            <Dragger
              name="file"
              multiple={false}
              beforeUpload={() => false}
              accept=".csv"
              onChange={handleCsvUpload}
              style={{
                background: '#fafafa',
                borderRadius: '12px',
                padding: '40px 20px',
                border: '2px dashed #e6e6e6'
              }}
            >
              <p className="ant-upload-drag-icon" style={{ marginBottom: '16px' }}>
                <UploadOutlined style={{ fontSize: '32px', color: '#1890ff' }} />
              </p>
              <p className="ant-upload-text" style={{ fontSize: '16px', marginBottom: '8px' }}>
                Click or drag CSV file to this area to upload
              </p>
              <p className="ant-upload-hint" style={{ color: '#8c8c8c' }}>
                CSV should contain: Name, Phone Number, Property Address
              </p>
            </Dragger>
          </div>
        ) : (
          <div>
            <Space style={{ 
              marginBottom: 24, 
              justifyContent: 'space-between', 
              width: '100%',
              background: '#fafafa',
              padding: '16px',
              borderRadius: '8px'
            }}>
              <Title level={5} style={{ margin: 0 }}>Contact List</Title>
              <Space>
                <Button 
                  icon={<DeleteOutlined />} 
                  onClick={resetUpload}
                  disabled={activeBatch.length > 0}
                >
                  Cancel and Upload New CSV
                </Button>
                <Button 
                  type="primary"
                  onClick={startBatchCalls}
                  disabled={!basePrompt.trim() || activeBatch.length > 0}
                  loading={processing}
                  style={{ borderRadius: '6px' }}
                >
                  Start Next 10 Calls
                </Button>
              </Space>
            </Space>
            <Table 
              columns={columns} 
              dataSource={contacts}
              pagination={false}
              style={{ 
                background: 'white',
                borderRadius: '8px'
              }}
            />
          </div>
        )}
      </Space>
    </Card>
  );
};

export default CampaignManager;