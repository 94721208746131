import React, { useEffect, useState, useRef } from 'react';
import { Card, Button, Typography, Space, Alert, List } from 'antd';
import { PlayCircleOutlined, StopOutlined } from '@ant-design/icons';

const { Text } = Typography;

const TestCallMonitor = () => {
  const [status, setStatus] = useState('Disconnected');
  const [connectionState, setConnectionState] = useState('disconnected');
  const [messages, setMessages] = useState([]);
  const wsRef = useRef(null);
  const [testCallId, setTestCallId] = useState(`TEST-${Date.now()}`);
  const [callInProgress, setCallInProgress] = useState(false);

  useEffect(() => {
    initializeWebSocket();
    return () => cleanup();
  }, []);

  const initializeWebSocket = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      setStatus('Error: No token available');
      return;
    }

    const wsUrl = `wss://aigent-backend.onrender.com/ws/call-monitor?token=${token}&callSid=${testCallId}`;
    console.log('[Test] Connecting to:', wsUrl);

    const websocket = new WebSocket(wsUrl);
    wsRef.current = websocket;

    websocket.onopen = () => {
      console.log('[Test] WebSocket connection opened');
      setConnectionState('connected');
      setStatus('Connected');
    };

    websocket.onmessage = (event) => {
      const message = JSON.parse(event.data);
      handleMessage(message);
    };

    websocket.onerror = (error) => {
      console.error('[Test] WebSocket error:', error);
      setConnectionState('error');
      setStatus('Error: Connection failed');
    };

    websocket.onclose = (event) => {
      console.log('[Test] Connection closed:', event.code, event.reason);
      setConnectionState('disconnected');
      setStatus('Disconnected');
      setCallInProgress(false);
    };
  };

  const cleanup = () => {
    console.log('[Test] Cleanup function called');
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      wsRef.current.close(1000, 'Test completed');
    }
  };

  const handleMessage = (message) => {
    setMessages((prev) => [...prev, message]);
    if (message.type === 'call_status') {
      console.log('Received call_status:', message.data);
    } else if (message.type === 'call_summary') {
      console.log('Received call_summary:', message.data);
      setCallInProgress(false);
    }
  };

  const startNewCall = () => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      setCallInProgress(true);
      simulateCall();
    } else {
      setStatus('Error: WebSocket not connected');
    }
  };

  const simulateCall = () => {
    // Simulate call_status updates
    let duration = 0;
    const callStatusInterval = setInterval(() => {
      duration += 5;
      const callStatusMessage = {
        type: 'call_status',
        data: {
          status: 'in-progress',
          duration,
          timestamp: new Date().toISOString(),
        },
      };
      handleMessage(callStatusMessage);
    }, 5000);

    // Simulate call completion and summary
    setTimeout(() => {
      clearInterval(callStatusInterval);
      const callCompletedMessage = {
        type: 'call_status',
        data: {
          status: 'completed',
          duration,
          timestamp: new Date().toISOString(),
        },
      };
      handleMessage(callCompletedMessage);

      // Simulate receiving call summary
      const callSummaryMessage = {
        type: 'call_summary',
        data: {
          callSid: testCallId,
          summary: 'This is a simulated summary of the call.',
          structuredSummary: {
            mainObjective: 'Test Call Simulation',
            outcome: 'Successfully tested summary functionality.',
            keyPoints: ['Simulated key point 1', 'Simulated key point 2'],
            nextSteps: ['Proceed with real calls when ready'],
          },
          timestamp: new Date().toISOString(),
        },
      };

      // Simulate a delay before receiving the summary
      setTimeout(() => {
        handleMessage(callSummaryMessage);
      }, 2000);
    }, 15000); // Simulate a 15-second call duration
  };

  const stopCall = () => {
    if (wsRef.current?.readyState === WebSocket.OPEN) {
      setCallInProgress(false);
      const callStoppedMessage = {
        type: 'call_status',
        data: {
          status: 'stopped',
          duration: 0,
          timestamp: new Date().toISOString(),
        },
      };
      handleMessage(callStoppedMessage);
    }
  };

  return (
    <Card title="Test Call Monitor" style={{ maxWidth: 600, margin: '20px auto' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        <Alert
          message={`Connection Status: ${status}`}
          type={
            connectionState === 'connected'
              ? 'success'
              : connectionState === 'connecting'
              ? 'info'
              : connectionState === 'error'
              ? 'error'
              : 'warning'
          }
          showIcon
        />

        <Space>
          <Button
            type="primary"
            icon={<PlayCircleOutlined />}
            onClick={startNewCall}
            disabled={!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN || callInProgress}
          >
            Start Test Call
          </Button>

          <Button
            danger
            icon={<StopOutlined />}
            onClick={stopCall}
            disabled={!wsRef.current || wsRef.current.readyState !== WebSocket.OPEN || !callInProgress}
          >
            Stop Call
          </Button>
        </Space>

        <Card title="Messages" size="small">
          <List
            size="small"
            bordered
            dataSource={messages}
            renderItem={(msg, index) => (
              <List.Item key={index}>
                <Text code>{msg.type}</Text>: {JSON.stringify(msg.data)}
              </List.Item>
            )}
          />
        </Card>
      </Space>
    </Card>
  );
};

export default TestCallMonitor;
