import React, { useState } from 'react';
import { Typography, Slider, Input, message, Spin, Card } from 'antd';
import { useCredits } from '../../contexts/CreditContext';
import { useAuth } from '../../contexts/AuthContext';
import PayPalButton from './PayPalButton';
import './CreditsView.css';

const { Title, Text } = Typography;

const MIN_CREDITS = 2;
const MAX_CREDITS = 100;
const DEFAULT_CREDITS = 40;

const CreditsView = () => {
  const [amount, setAmount] = useState(DEFAULT_CREDITS);
  const [refreshing, setRefreshing] = useState(false);
  const { balance, loading, refreshBalance } = useCredits();
  const { user } = useAuth();

  const retryBalanceRefresh = async () => {
    setRefreshing(true);
    try {
      // First immediate check
      await refreshBalance();
      message.success('Credits purchased successfully!');
    } catch (error) {
      console.error('Balance refresh failed:', error);
      message.error('Failed to refresh balance.');
    } finally {
      setRefreshing(false);
    }
  };

  const handlePaymentSuccess = () => {
    retryBalanceRefresh();
    setAmount(DEFAULT_CREDITS);
  };

  const handlePaymentError = (error) => {
    setRefreshing(false);
    message.error('Payment failed: ' + error.message);
  };

  if (loading || balance === null) {
    return (
      <div className="credits-view">
        <div className="credits-content" style={{ textAlign: 'center', paddingTop: '50px' }}>
          <Spin size="large" />
        </div>
      </div>
    );
  }

  return (
    <div className="credits-view">
      <div className="credits-content">
        <Card className="balance-card">
          <Title level={3}>Current Balance</Title>
          <Text className="balance-amount">
            ${balance.toFixed(2)}
            {refreshing && <Spin size="small" style={{ marginLeft: '10px' }} />}
          </Text>
        </Card>

        <Card className="purchase-card">
          <div className="credits-header">
            <Title level={2}>Buy Credits</Title>
            <Text className="subtitle">
              Select the amount and complete purchase with PayPal
            </Text>
          </div>

          <div className="purchase-container">
            <div className="amount-display">
              <div className="input-group">
                <Text className="currency">$</Text>
                <Input
                  value={amount}
                  onChange={(e) => {
                    const value = parseFloat(e.target.value) || 0;
                    const clampedValue = Math.min(Math.max(value, MIN_CREDITS), MAX_CREDITS);
                    setAmount(clampedValue);
                  }}
                  className="amount-input"
                  type="number"
                  min={MIN_CREDITS}
                  max={MAX_CREDITS}
                />
              </div>
            </div>

            <div className="slider-container">
              <Slider
                value={amount}
                onChange={setAmount}
                min={MIN_CREDITS}
                max={MAX_CREDITS}
                className="credits-slider"
              />
              <div className="slider-labels">
                <Text>${MIN_CREDITS}</Text>
                <Text>${MAX_CREDITS}</Text>
              </div>
            </div>

            <div className="credits-info">
              <Text>You will receive</Text>
              <Text className="credits-amount">{amount} credits</Text>
            </div>

            {amount >= MIN_CREDITS && (
              <div className="paypal-container">
                <PayPalButton
                  amount={amount}
                  onSuccess={handlePaymentSuccess}
                  onError={handlePaymentError}
                />
              </div>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default CreditsView;