import React, { createContext, useState, useContext, useEffect } from 'react';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  onAuthStateChanged,
  sendEmailVerification,
  sendPasswordResetEmail
} from 'firebase/auth';
import { auth, googleProvider } from '../firebase/firebase';
import { authAPI } from '../services/api';

const AuthContext = createContext({});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [verificationEmailSent, setVerificationEmailSent] = useState(false);

  const ALLOWED_EMAIL = 'manuntvg1994@gmail.com';
  const MAINTENANCE_MESSAGE = "We're currently performing system maintenance and updates. The application will be available again soon. Thank you for your patience!";

  const exchangeFirebaseToken = async (firebaseUser) => {
    try {
      console.log('Exchanging Firebase token for backend token');
      const firebaseToken = await firebaseUser.getIdToken();
      const response = await authAPI.firebase(firebaseToken);
      
      if (response.accessToken) {
        localStorage.setItem('token', response.accessToken);
        return response.accessToken;
      } else {
        throw new Error('No access token received from backend');
      }
    } catch (error) {
      console.error('Error exchanging Firebase token:', error);
      throw error;
    }
  };

  // Email/Password Sign Up with verification
  const signUp = async (email, password) => {
    throw new Error(MAINTENANCE_MESSAGE);
  };

  // Email/Password Sign In with verification check
  const signIn = async (email, password) => {
    throw new Error(MAINTENANCE_MESSAGE);
  };

  // Resend verification email
  const resendVerificationEmail = async () => {
    throw new Error(MAINTENANCE_MESSAGE);
  };

  // Send password reset email
  const resetPassword = async (email) => {
    throw new Error(MAINTENANCE_MESSAGE);
  };

  // Google Sign In with email restriction
  const signInWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      
      if (result.user.email !== ALLOWED_EMAIL) {
        await signOut(auth);
        throw new Error(MAINTENANCE_MESSAGE);
      }

      return result;
    } catch (error) {
      console.error('Google sign-in error:', error);
      throw handleAuthError(error);
    }
  };

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.removeItem('token');
      setUser(null);
      setVerificationEmailSent(false);
    } catch (error) {
      console.error('Logout error:', error);
      throw handleAuthError(error);
    }
  };

  const handleAuthError = (error) => {
    if (error.message === MAINTENANCE_MESSAGE) {
      return error;
    }

    let message = 'An error occurred during authentication';
    
    switch (error.code) {
      case 'auth/email-already-in-use':
        message = 'This email is already registered. Please sign in instead.';
        break;
      case 'auth/invalid-email':
        message = 'Please enter a valid email address.';
        break;
      case 'auth/user-disabled':
        message = 'This account has been disabled. Please contact support.';
        break;
      case 'auth/user-not-found':
        message = 'No account found with this email. Please sign up.';
        break;
      case 'auth/wrong-password':
        message = 'Invalid email or password.';
        break;
      case 'auth/too-many-requests':
        message = 'Too many failed attempts. Please try again later.';
        break;
      case 'auth/weak-password':
        message = 'Password should be at least 6 characters.';
        break;
      default:
        message = error.message || 'Authentication failed. Please try again.';
    }
    
    return new Error(message);
  };

  // Single source of truth for token exchange and verification status
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (firebaseUser) => {
      try {
        if (firebaseUser) {
          // Check if email is allowed
          if (firebaseUser.email !== ALLOWED_EMAIL) {
            await signOut(auth);
            localStorage.removeItem('token');
            setUser(null);
            throw new Error(MAINTENANCE_MESSAGE);
          }
          
          // Only exchange token if we have a verified Firebase user
          if (firebaseUser.emailVerified) {
            await exchangeFirebaseToken(firebaseUser);
          }
        } else {
          localStorage.removeItem('token');
        }
        setUser(firebaseUser);
      } catch (error) {
        console.error('Auth state change error:', error);
        localStorage.removeItem('token');
        setUser(null);
      } finally {
        setLoading(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const value = {
    user,
    loading,
    signIn,
    signUp,
    signInWithGoogle,
    logout,
    resetPassword,
    resendVerificationEmail,
    verificationEmailSent,
    isEmailVerified: user?.emailVerified || false
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : null}
    </AuthContext.Provider>
  );
};

export default AuthProvider;