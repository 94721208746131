import React, { useEffect, useState, useRef } from 'react';
import { Card, Row, Col, Typography, Spin, Alert } from 'antd';
import { callsAPI } from '../../../../services/api';
import { getCallOutcome } from '../../../../utils/callOutcomeUtils';

const { Title, Text } = Typography;

const BASE_WS_URL = process.env.REACT_APP_API_URL?.replace('http', 'ws') || 'wss://aigent-backend.onrender.com';

const ProgressStep = ({ phoneNumber, callData, onCallComplete }) => {
  const [callStatus, setCallStatus] = useState(null);
  const [connectionState, setConnectionState] = useState('connecting');
  const [summary, setSummary] = useState(null);
  const [creditInfo, setCreditInfo] = useState(null);
  const [error, setError] = useState(null);
  const [conversation, setConversation] = useState([]);
  const wsRef = useRef(null);
  const pollTimeoutRef = useRef(null);
  const reconnectingRef = useRef(false);
  const lastMessageTimestamp = useRef(null);

  useEffect(() => {
    if (!callData?.callSid) {
      console.error('[ProgressStep] Missing callSid. Cannot establish WebSocket connection.');
      setError('Call initialization failed');
      return;
    }

    const token = localStorage.getItem('token');
    if (!token) {
      console.error('[ProgressStep] Authentication token not found.');
      setError('Authentication error');
      return;
    }

    let reconnectTimeout;
    let isConnecting = false;

    const connectWebSocket = () => {
      if (isConnecting || reconnectingRef.current) return;
      isConnecting = true;

      const wsUrl = `${BASE_WS_URL}/ws/call-monitor?token=${encodeURIComponent(token)}&callSid=${encodeURIComponent(callData.callSid)}`;
      console.log('[ProgressStep] Connecting to WebSocket:', wsUrl);

      const ws = new WebSocket(wsUrl);
      wsRef.current = ws;

      ws.onopen = () => {
        console.log('[ProgressStep] WebSocket connection opened');
        setConnectionState('connected');
        setError(null);
        isConnecting = false;
        reconnectingRef.current = false;
      };

      ws.onclose = (event) => {
        console.log('[ProgressStep] WebSocket connection closed:', event.code, event.reason);
        setConnectionState('closed');
        isConnecting = false;
        
        if (!summary && callStatus?.status !== 'completed' && event.code !== 1000) {
          reconnectingRef.current = true;
          console.log('[ProgressStep] Attempting reconnection...');
          reconnectTimeout = setTimeout(() => connectWebSocket(), 2000);
        }
      };

      ws.onerror = (err) => {
        console.error('[ProgressStep] WebSocket error:', err);
        if (!summary && !reconnectingRef.current) {
          setConnectionState('error');
          reconnectingRef.current = true;
          reconnectTimeout = setTimeout(() => connectWebSocket(), 2000);
        }
      };

      ws.onmessage = (event) => {
        try {
          const message = JSON.parse(event.data);
          
          if (message.timestamp && lastMessageTimestamp.current === message.timestamp) {
            return;
          }
          lastMessageTimestamp.current = message.timestamp;
          
          console.log('[ProgressStep] Received WebSocket message:', message);
          handleMessage(message);
        } catch (err) {
          console.error('[ProgressStep] Error parsing WebSocket message:', err);
        }
      };
    };

    connectWebSocket();

    return () => {
      if (reconnectTimeout) {
        clearTimeout(reconnectTimeout);
      }
      if (wsRef.current) {
        wsRef.current.close(1000, 'Component unmounted');
      }
      reconnectingRef.current = false;
      lastMessageTimestamp.current = null;
    };
  }, [callData?.callSid, callStatus?.status, summary]);

  const handleMessage = (message) => {
    console.log('[ProgressStep] Processing message:', message);
    
    if (!message.type) {
      console.error('[ProgressStep] Message missing type:', message);
      return;
    }

    switch (message.type) {
      case 'conversation.item.input_audio_transcription.completed':
        if (message.transcript) {
          setConversation(prev => [...prev, {
            speaker: 'human',
            text: message.transcript
          }]);
        }
        break;

      case 'call_status':
        console.log('[ProgressStep] Call status update:', message.data);
        setCallStatus(message.data);
        if (message.data.status === 'completed') {
          console.log('[ProgressStep] Call completed. Starting to poll for summary.');
          if (wsRef.current?.readyState === WebSocket.OPEN) {
            wsRef.current.close(1000, 'Call completed');
          }
          startPolling(callData.callSid);
        }
        break;

      case 'credit_update':
        console.log('[ProgressStep] Credit info update:', message.data);
        setCreditInfo({
          minutesUsed: message.data.minutes || 0,
          creditsDeducted: message.data.amount || 0,
          costPerMinute: message.data.rate || 0
        });
        break;

        case 'call_summary':
        console.log('[ProgressStep] Received summary message:', message);
        
        if (!message.data) {
          console.error('[ProgressStep] Invalid summary data structure:', message.data);
          setError('Invalid summary data received');
          return;
        }

        try {
          const fullConversation = message.data.conversation || conversation;
          
          // Use the utility function
          const { status, confidence } = getCallOutcome(fullConversation, message.data);

          const summaryData = {
            ...message.data,
            status,
            conversation: fullConversation,
            summary: typeof message.data.summary === 'object' ? 
              message.data.summary.raw || message.data.summary.message || '' : 
              message.data.summary || '',
            structuredSummary: message.data.structuredSummary || 
              (typeof message.data.summary === 'object' ? message.data.summary.parsed : null),
            creditInfo: creditInfo || null,
            metadata: {
              ...(typeof message.data.metadata === 'object' ? message.data.metadata : {}),
              statusConfidence: confidence,
              conversationLength: fullConversation.length
            }
          };

          console.log('[ProgressStep] Processed summary data:', summaryData);
          setSummary(summaryData);
          onCallComplete(callData.callSid, summaryData);
        } catch (err) {
          console.error('[ProgressStep] Error processing summary:', err);
          setError('Error processing call summary');
        }
        break;
  
        case 'error':
          console.error('[ProgressStep] Error message received:', message.data);
          setError(message.data.message || 'An error occurred');
          break;
  
        default:
          console.log('[ProgressStep] Unhandled message type:', message.type);
      }
  };

  const startPolling = async (callSid) => {
    const maxAttempts = 10;
    const interval = 3000;
    let attempts = 0;

    const pollOnce = async () => {
      if (attempts >= maxAttempts) {
        console.log('[ProgressStep] Max polling attempts reached');
        setError('Could not retrieve call summary');
        return;
      }

      try {
        console.log(`[ProgressStep] Polling for summary, attempt ${attempts + 1}`);
        const data = await callsAPI.getCallSummary(callSid);

        if (data.success && (data.summary || data.structuredSummary)) {
          const summaryWithExtras = {
            ...data,
            summary: typeof data.summary === 'object' ? 
              data.summary.raw || data.summary.message || '' : 
              data.summary || '',
            structuredSummary: data.structuredSummary || 
              (typeof data.summary === 'object' ? data.summary.parsed : null),
            creditInfo: creditInfo || {
              minutesUsed: 0,
              creditsDeducted: 0,
              costPerMinute: 0
            },
            status: 'completed',
            callSid: callSid
          };

          console.log('[ProgressStep] Setting final summary:', summaryWithExtras);
          setSummary(summaryWithExtras);
          onCallComplete(callSid, summaryWithExtras);
          return;
        }

        attempts++;
        pollTimeoutRef.current = setTimeout(pollOnce, interval);
      } catch (error) {
        console.error('[ProgressStep] Error fetching summary:', error);
        setError('Failed to retrieve call summary');
      }
    };

    pollOnce();
  };

  const renderLoadingState = () => {
    let message;
    switch (callStatus?.status) {
      case 'initiated':
        message = 'Preparing your call...';
        break;
      case 'in-progress':
        message = 'Call in progress...';
        break;
      case 'completed':
        message = 'Generating call summary...';
        break;
      default:
        message = 'Initializing call...';
    }

    return (
      <Row gutter={[24, 32]} justify="center" align="middle">
        <Col span={24} style={{ textAlign: 'center' }}>
          <Spin size="large" />
          <Title level={4} style={{ marginTop: 16 }}>
            {message}
          </Title>
          <Text type="secondary">
            Please wait while we process your call
          </Text>
        </Col>
      </Row>
    );
  };

  return (
    <Card className="progress-card">
      {error ? (
        <Alert
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      ) : (
        renderLoadingState()
      )}
    </Card>
  );
};

export default ProgressStep;