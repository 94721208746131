import React, { useState } from 'react';
import { 
  Button, 
  Space
} from 'antd';
import CampaignManager from './CampaignManager';
import ResultsManager from './ResultsManager';

const ExperimentalPage = () => {
  const [activeTab, setActiveTab] = useState('campaign');
  // Add state to store contacts and CSV data
  const [contacts, setContacts] = useState([]);
  const [completedCalls, setCompletedCalls] = useState([]);
  const [csvUploaded, setCsvUploaded] = useState(false);

  // Pass these handlers to CampaignManager
  const handleCallStart = (batchContacts) => {
    // Update contacts with in-progress status
    setContacts(prevContacts => 
      prevContacts.map(contact => ({
        ...contact,
        status: batchContacts.some(bc => bc.key === contact.key) 
          ? 'in-progress' 
          : contact.status
      }))
    );
  };

  const handleCallComplete = (results) => {
    // Update contacts status
    setContacts(prevContacts => 
      prevContacts.map(contact => {
        const result = results.find(r => r.key === contact.key);
        return result 
          ? { ...contact, status: 'completed' }
          : contact;
      })
    );
    
    // Add to completed calls
    setCompletedCalls(prev => [...prev, ...results]);
  };

  return (
    <Space direction="vertical" size="large" style={{ width: '100%', textAlign: 'center' }}>
      {/* Tab buttons remain the same */}
      <div style={{ 
        display: 'inline-block', 
        marginBottom: '24px',
        background: 'white',
        padding: '0px',
        borderRadius: '8px',
      }}>
        <Space size={0}>
          <Button
            type="text"
            onClick={() => setActiveTab('campaign')}
            style={{
              height: '40px',
              padding: '0 24px',
              borderRadius: '6px 0px 0px 6px',
              fontWeight: 500,
              backgroundColor: activeTab === 'campaign' ? '#2c2c2c' : '#EDEDED',
              color: activeTab === 'campaign' ? 'white' : '#666666',
              border: 'none'
            }}
          >
            Start Campaign
          </Button>
          <Button
            type="text"
            onClick={() => setActiveTab('results')}
            style={{
              height: '40px',
              padding: '0 24px',
              borderRadius: '0px 6px 6px 0px',
              fontWeight: 500,
              backgroundColor: activeTab === 'results' ? '#2c2c2c' : '#EDEDED',
              color: activeTab === 'results' ? 'white' : '#666666',
              border: 'none',
              marginLeft: '0px'
            }}
          >
            View Results
          </Button>
        </Space>
      </div>
      
      {activeTab === 'campaign' ? (
        <CampaignManager 
          contacts={contacts}
          setContacts={setContacts}
          csvUploaded={csvUploaded}
          setCsvUploaded={setCsvUploaded}
          onCallStart={handleCallStart}
          onCallComplete={handleCallComplete}
        />
      ) : (
        <ResultsManager 
          results={completedCalls}
          activeContacts={contacts.filter(c => c.status === 'in-progress')}
        />
      )}
    </Space>
  );
};

export default ExperimentalPage;