// src/utils/callOutcomeUtils.js
export const getCallOutcome = (conversation, summary) => {
    // First check the structured summary outcome
    if (summary?.structuredSummary?.outcome) {
      const outcome = summary.structuredSummary.outcome.toLowerCase();
      
      // First check for direct "achieved" or "completed" statements
      if (outcome.includes('achieved') || 
          outcome.includes('completed') || 
          outcome.includes('successful')) {
        return {
          status: 'completed',
          confidence: 'high'
        };
      }
  
      // Check for objective success without explicit "achieved" word
      if (outcome.includes('is available') ||
          outcome.includes('was available') ||
          outcome.includes('has been')) {
        return {
          status: 'completed',
          confidence: 'medium'
        };
      }
  
      // Check for explicit failure indicators
      const failureIndicators = [
        'not available',
        'unable to',
        'failed',
        'cannot',
        'declined',
        'rejected',
        'unsuccessful'
      ];
  
      if (failureIndicators.some(indicator => outcome.includes(indicator))) {
        return {
          status: 'failed',
          confidence: 'high'
        };
      }
    }
  
    // Handle conversation check
    if (conversation) {
      // Check if conversation is a string (older format) or array (newer format)
      const conversationArray = Array.isArray(conversation) 
        ? conversation 
        : typeof conversation === 'string'
          ? [{ text: conversation }]
          : [];
  
      // If we have an [END_CALL] marker
      const lastMessage = conversationArray[conversationArray.length - 1];
      if (lastMessage?.text?.includes('[END_CALL]')) {
        return {
          status: 'completed',
          confidence: 'medium'
        };
      }
  
      // Check conversation for success indicators
      if (conversationArray.length > 0) {
        const hasPositiveResponse = conversationArray.some(msg => {
          const text = (msg.text || '').toLowerCase();
          return text.includes('yes') ||
                 text.includes('have it') ||
                 text.includes('available') ||
                 text.includes('we do') ||
                 text.includes('can help');
        });
  
        if (hasPositiveResponse) {
          return {
            status: 'completed',
            confidence: 'low'
          };
        }
      }
  
      // If conversation exists but no clear outcome
      return {
        status: 'incomplete',
        confidence: 'low'
      };
    }
  
    // Default case - no conversation or summary
    return {
      status: 'failed',
      confidence: 'low'
    };
  };