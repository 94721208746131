import React from 'react';
import { Form, Input, Button, Typography, Alert } from 'antd';
import { MailOutlined, LockOutlined, EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

const { Text } = Typography;

const AuthForm = ({ 
  isLogin, 
  onSubmit, 
  loading, 
  error,
  onToggleMode 
}) => {
  return (
    <div className="auth-form-container1">
      {error && (
        <Alert
          message={error}
          type="error"
          showIcon
          className="auth-error"
        />
      )}

      <Form
        name="auth-form"
        onFinish={onSubmit}
        layout="vertical"
        requiredMark={false}
        size="large"
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter a valid email' }
          ]}
        >
          <Input 
            prefix={<MailOutlined />}
            placeholder="Email address"
            className="auth-input"
          />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please enter your password' },
            { min: 6, message: 'Password must be at least 6 characters' }
          ]}
        >
          <Input.Password
            prefix={<LockOutlined />}
            placeholder="Password"
            className="auth-input"
            iconRender={(visible) => (
              visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
            )}
          />
        </Form.Item>

        {!isLogin && (
          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Passwords do not match'));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined />}
              placeholder="Confirm password"
              className="auth-input"
              iconRender={(visible) => (
                visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
              )}
            />
          </Form.Item>
        )}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            className="submit-button"
            block
          >
            {isLogin ? 'Sign In' : 'Create Account'}
          </Button>
        </Form.Item>
      </Form>

      <div className="auth-switch">
        <Text type="secondary">
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <Button 
            type="link" 
            onClick={onToggleMode}
            className="switch-button"
          >
            {isLogin ? 'Sign up' : 'Sign in'}
          </Button>
        </Text>
      </div>
    </div>
  );
};

export default AuthForm;