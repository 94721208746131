import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, setPersistence, browserSessionPersistence } from "firebase/auth";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCBGu31zjGhRUgq-_4-f1nazBGbB6lgM2o",
  authDomain: "voxi-42f40.firebaseapp.com",
  projectId: "voxi-42f40",
  storageBucket: "voxi-42f40.firebasestorage.app",
  messagingSenderId: "235365889900",
  appId: "1:235365889900:web:16fa2b004b983d34296549",
  measurementId: "G-KS1JCEGZ9V"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);
const googleProvider = new GoogleAuthProvider();

// Set session persistence
setPersistence(auth, browserSessionPersistence)
  .catch((error) => {
    console.error("Firebase persistence error:", error);
  });

export { auth, googleProvider, analytics };