import React, { useEffect, useRef, useState } from 'react';
import { Spin, Modal } from 'antd';
import { useCredits } from '../../contexts/CreditContext';
import { paymentAPI } from '../../services/api';
import { debounce } from 'lodash';

const PayPalButtonComponent = ({ amount, onSuccess, onError }) => {
  const { balance, refreshBalance } = useCredits();
  const [isLoading, setIsLoading] = useState(false);
  const [processingPayment, setProcessingPayment] = useState(false);
  const containerRef = useRef(null);
  const buttonsInstanceRef = useRef(null);
  const mounted = useRef(true);
  const initialization = useRef(false);
  const previousBalanceRef = useRef(balance);

  const balanceHasUpdated = () => {
    return balance > previousBalanceRef.current;
  };

  const checkPaymentStatus = async (orderId, attempt = 1, maxAttempts = 5) => {
    try {
      const { status } = await paymentAPI.getPaymentStatus(orderId);
      if (status === 'completed') {
        return true;
      }
      if (attempt < maxAttempts && (status === 'pending' || !status)) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return checkPaymentStatus(orderId, attempt + 1, maxAttempts);
      }
      return false;
    } catch (error) {
      if (attempt < maxAttempts) {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        return checkPaymentStatus(orderId, attempt + 1, maxAttempts);
      }
      return false;
    }
  };

  const refreshBalanceWithRetries = async () => {
    let attempts = 0;
    const maxAttempts = 10;
    const delay = 3000;

    while (attempts < maxAttempts && mounted.current) {
      await refreshBalance();

      if (balanceHasUpdated()) {
        break;
      }

      await new Promise((resolve) => setTimeout(resolve, delay));
      attempts++;
    }
  };

  useEffect(() => {
    const loadSDK = async () => {
      try {
        const config = await paymentAPI.getConfig();
        const script = document.createElement('script');
        script.src = `https://www.paypal.com/sdk/js?client-id=${config.clientId}&currency=USD&intent=capture`;
        script.onload = () => {
          initialization.current = true;
          debouncedRender(amount);
        };
        document.body.appendChild(script);
      } catch (error) {
        if (!error.message?.includes('zoid destroyed')) {
          console.error('PayPal SDK load error:', error);
        }
      }
    };
    loadSDK();
  }, []);

  const debouncedRender = useRef(
    debounce(async (currentAmount) => {
      if (!mounted.current || !window.paypal || !containerRef.current) return;
      if (!initialization.current) return;

      try {
        if (buttonsInstanceRef.current) {
          buttonsInstanceRef.current.close();
          buttonsInstanceRef.current = null;
        }

        containerRef.current.innerHTML = '';

        buttonsInstanceRef.current = window.paypal.Buttons({
          style: {
            layout: 'vertical',
            shape: 'rect',
            label: 'paypal',
            height: 55,
          },

          createOrder: async (data, actions) => {
            try {
              const response = await paymentAPI.createPayment(currentAmount);
              return response.orderId;
            } catch (error) {
              console.error('Error creating payment:', error);
              throw error;
            }
          },

          onApprove: async (data, actions) => {
            try {
              setProcessingPayment(true);
              await actions.order.capture();
              
              await new Promise(r => setTimeout(r, 5000));
              
              let attempts = 0;
              let status;
              
              while (attempts < 12) {
                const response = await paymentAPI.getPaymentStatus(data.orderID);
                status = response.status;
                
                if (status === 'completed') {
                  await refreshBalance();
                  onSuccess?.();
                  break;
                }
                
                await new Promise(r => setTimeout(r, 5000));
                attempts++;
              }
          
              if (status !== 'completed') {
                console.warn('Payment might still be processing. Please refresh page in a minute.');
              }
            } catch (error) {
              console.error('Payment approval error:', error);
              onError?.(error);
            } finally {
              setProcessingPayment(false);
            }
          },

          onCancel: (data) => {
            console.log('Payment cancelled:', data);
          },

          onError: (err) => {
            if (mounted.current) {
              if (!err.message?.includes('zoid destroyed')) {
                console.error('PayPal error:', err);
                onError?.(err);
              }
            }
          },
        });

        if (mounted.current && buttonsInstanceRef.current) {
          await buttonsInstanceRef.current.render(containerRef.current);
        }
      } catch (error) {
        if (mounted.current) {
          if (!error.message?.includes('zoid destroyed')) {
            console.error('PayPal initialization error:', error);
            onError?.(error);
          }
        }
      }
    }, 500)
  ).current;

  useEffect(() => {
    mounted.current = true;
    initialization.current = true;

    debouncedRender(amount);

    return () => {
      mounted.current = false;
      initialization.current = false;
      debouncedRender.cancel();

      if (buttonsInstanceRef.current) {
        try {
          buttonsInstanceRef.current.close();
        } catch (err) {
          if (!err.message?.includes('zoid destroyed')) {
            console.error('Error cleaning up PayPal buttons:', err);
          }
        }
        buttonsInstanceRef.current = null;
      }
    };
  }, [amount, onSuccess, onError, refreshBalance]);

  return (
    <div className="paypal-button-wrapper" style={{ minHeight: '200px' }}>
      <div ref={containerRef} className="paypal-button-container" />
      
      <Modal
        title="Processing Payment"
        open={processingPayment}
        footer={null}
        closable={false}
        centered
        maskClosable={false}
      >
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
          <div style={{ marginTop: '20px', fontSize: '16px' }}>
            Payment is being processed...
          </div>
          <div style={{ marginTop: '10px', color: '#888' }}>
            This may take up to one minute to complete.
            Please don't close this window.
          </div>
        </div>
      </Modal>

      {(!window.paypal || isLoading) && (
        <div style={{ textAlign: 'center', padding: '20px' }}>
          <Spin size="large" />
          <div style={{ marginTop: '10px' }}>Loading PayPal...</div>
        </div>
      )}
    </div>
  );
};

export default React.memo(PayPalButtonComponent);