import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ExperimentalLayout = ({ children }) => {
  return (
    <Layout style={{ background: '#F7F8F9', minHeight: '100vh' }}>
      <Content 
        style={{ 
          padding: '40px 24px',
          maxWidth: '1200px', 
          margin: '0 auto',
          width: '100%'
        }}
      >
        {children}
      </Content>
    </Layout>
  );
};

export default ExperimentalLayout;