import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'https://aigent-backend.onrender.com';

// Track ongoing calls to prevent duplicates
const ongoingCalls = new Set();
const retryAttempts = new Set();

// Create axios instance for /api/v1 endpoints
const api = axios.create({
  baseURL: `${BASE_URL}/api/v1`,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Create axios instance for root endpoints
const rootApi = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add auth interceptor to both instances
const addAuthInterceptor = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem('token');
      console.log('API Request:', {
        url: config.url,
        method: config.method,
        token: token ? 'present' : 'missing'
      });
      
      // Add request tracking for make-call endpoint
      if (config.url === '/make-call') {
        const callKey = JSON.stringify(config.data);
        const isRetry = retryAttempts.has(callKey);
        
        if (!isRetry && ongoingCalls.has(callKey)) {
          return Promise.reject({
            message: 'Call already in progress',
            isSystemBusy: false,
            error: true
          });
        }
        
        if (!isRetry) {
          ongoingCalls.add(callKey);
          // Clear after 5 seconds
          setTimeout(() => ongoingCalls.delete(callKey), 5000);
        }
      }
      
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      console.log('API Response:', {
        url: response.config.url,
        status: response.status,
        data: response.data
      });
      return response.data;
    },
    (error) => {
      console.error('API Error:', {
        url: error.config?.url,
        status: error.response?.status,
        data: error.response?.data
      });

      // Special handling for 503 errors
      if (error.response?.status === 503) {
        return Promise.reject({
          isSystemBusy: true,
          message: error.response.data.message || 'System is currently busy',
          currentCalls: error.response.data.currentCalls || 1,
          maxCalls: 1,
          status: 503,
          retryAfter: error.response.headers['retry-after'] || 2
        });
      }

      // For other errors
      const errorResponse = {
        error: true,
        isSystemBusy: false,
        message: error.response?.data?.message || error.response?.data?.error || error.message || 'An unexpected error occurred',
        status: error.response?.status,
        data: error.response?.data
      };
      return Promise.reject(errorResponse);
    }
  );
};

addAuthInterceptor(api);
addAuthInterceptor(rootApi);

// Auth API endpoints
export const authAPI = {
  loginWithApple: (data) => {
    return api.post('/auth/apple', data);
  },
  login: (email, password) => {
    return api.post('/auth/login', { email, password });
  },
  register: (email, password) => {
    return api.post('/auth/register', { email, password });
  },
  firebase: (firebaseToken) => {
    return api.post('/auth/firebase', { firebase_token: firebaseToken });
  }
};

// Helper function to standardize summary format
const standardizeSummaryFormat = (rawCallData) => {
  const summary = {
    raw: null,
    parsed: null,
    metadata: {}
  };

  if (rawCallData.summary) {
    if (typeof rawCallData.summary === 'string') {
      summary.raw = rawCallData.summary;
    } else {
      summary.raw = rawCallData.summary.raw;
      summary.parsed = rawCallData.summary.parsed;
    }
  }

  if (rawCallData.structuredSummary) {
    summary.parsed = rawCallData.structuredSummary;
  }

  summary.metadata = {
    duration: rawCallData.duration || rawCallData.metadata?.duration,
    status: rawCallData.status || 'unknown',
    timestamp: rawCallData.timestamp || new Date().toISOString(),
    cost: rawCallData.cost || rawCallData.creditInfo?.creditsDeducted,
    success: rawCallData.success || rawCallData.status === 'completed'
  };

  return summary;
};

// Calls API with updated formats
export const callsAPI = {
  estimateCost: (toNumber) => {
    return api.post('/estimate-cost', { toNumber });
  },

  makeCall: async (toPhoneNumber, prompt, isRetry = false) => {
    const callKey = JSON.stringify({ toPhoneNumber, prompt });
    try {
      if (isRetry) {
        retryAttempts.add(callKey);
      }
      const response = await api.post('/make-call', { toPhoneNumber, prompt });
      retryAttempts.delete(callKey);
      return response;
    } catch (error) {
      retryAttempts.delete(callKey);
      throw error;
    }
  },

  getCallSummary: async (callSid) => {
    try {
      const response = await rootApi.get('/call-summary', { params: { callSid } });
      return {
        success: true,
        summary: standardizeSummaryFormat(response)
      };
    } catch (error) {
      console.error('Failed to fetch call summary:', error);
      return {
        success: false,
        error: formatErrorMessage(error)
      };
    }
  },

  getCallHistory: async () => {
    const response = await api.get('/call-history');
    return response;
  }
};

// Monitor API endpoints
export const monitorAPI = {
  getActiveCalls: () => {
    return rootApi.get('/monitor/active-calls');
  },
  
  getCallDetails: (callSid) => {
    if (!callSid) throw new Error('Call SID is required');
    return rootApi.get(`/monitor/call/${callSid}`);
  },
  
  getErrors: (hours = 24) => {
    return rootApi.get('/monitor/errors', { params: { hours } });
  }
};

// Test API endpoints
export const testAPI = {
  testQueueSystem: (numberOfCalls = 3) => {
    return api.post('/test/queue-system', { numberOfCalls });
  },
  completeTestCall: (testCallSid) => {
    if (!testCallSid?.startsWith('TEST-')) {
      return Promise.reject(new Error('Invalid test call SID'));
    }
    return api.post(`/test/queue-system/complete/${testCallSid}`);
  },
  resetPhonePool: () => api.post('/test/reset-phone-pool')
};

// Credit API endpoints
export const creditAPI = {
  getBalance: () => api.get('/credits/balance'),
  purchaseCredits: (amount) => api.post('/credits/purchase', { amount }),
  getHistory: (days = 30) => api.get('/credits/history', { params: { days } }),
  estimateCost: (toNumber) => api.post('/estimate-cost', { toNumber })
};

// Payment API endpoints
export const paymentAPI = {
  getConfig: () => api.get('/payment-config'),
  createPayment: (amount) => api.post('/create-payment', { amount }),
  getPaymentStatus: (orderId) => api.get(`/payment-status/${orderId}`),
};

export const formatErrorMessage = (error) => {
  if (!error.status && !error.response) {
    return 'Unable to connect to the server. Please check your internet connection.';
  }
  if (error.code === 'ECONNABORTED') {
    return 'The request timed out. Please try again.';
  }
  
  switch (error.status) {
    case 401:
      return 'Please log in again to continue.';
    case 403:
      return 'You do not have permission to perform this action.';
    case 404:
      return 'The requested resource was not found.';
    case 429:
      return 'Too many requests. Please try again later.';
    case 500:
      return 'An internal server error occurred. Please try again later.';
    default:
      return error.message || 'An unexpected error occurred.';
  }
};

export default api;