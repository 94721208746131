import React, { useState, useCallback, useEffect } from 'react';
import { 
  Card, 
  Form, 
  Input, 
  Button, 
  Space, 
  Typography, 
  Alert, 
  Spin, 
  Modal, 
  message,
  Row,
  Col
} from 'antd';
import { 
  PhoneOutlined, 
  InfoCircleOutlined,
  WarningOutlined,
  CreditCardOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { callsAPI } from '../../../../services/api';
import { useCredits } from '../../../../contexts/CreditContext';
import { debounce } from 'lodash';
import VoxiGuideModal from './VoxiGuideModal';
import './SetupStep.css';

const { TextArea } = Input;
const { Title, Text, Link } = Typography;

const MIN_RETRY_DELAY = 10000;
const MAX_AUTO_RETRIES = 5;
const MAX_MANUAL_RETRIES = 5;
const RETRY_DELAY_MULTIPLIER = 1.5;
const MIN_CREDITS = 2;
const MAX_CREDITS = 50;
const DEFAULT_CREDITS = 40;

const SetupStep = ({ form, onSubmit, isLoading }) => {
  const [amount, setAmount] = useState(DEFAULT_CREDITS);
  const [costEstimate, setCostEstimate] = useState(null);
  const [estimateLoading, setEstimateLoading] = useState(false);
  const [estimateError, setEstimateError] = useState(null);
  const [submitError, setSubmitError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isGuideModalOpen, setIsGuideModalOpen] = useState(false);
  const [currentFormValues, setCurrentFormValues] = useState(null);
  const [retryState, setRetryState] = useState({
    isRetrying: false,
    attempt: 0,
    timeRemaining: 0,
    currentCalls: 0
  });
  const { balance } = useCredits();
  const navigate = useNavigate();

  useEffect(() => {
    // Load saved form data when component mounts
    const savedFormData = localStorage.getItem('callFormData');
    if (savedFormData) {
      const parsedData = JSON.parse(savedFormData);
      form.setFieldsValue(parsedData);
    }
  }, [form]);

  const getEstimatedDuration = useCallback(() => {
    if (!costEstimate || balance === null) return null;
    const minutesAvailable = balance / costEstimate.perMinute.total;
    return Math.floor(minutesAvailable);
  }, [costEstimate, balance]);

  const estimateCost = useCallback(
    debounce(async (phoneNumber) => {
      try {
        setEstimateLoading(true);
        setEstimateError(null);
        const data = await callsAPI.estimateCost(phoneNumber);
        setCostEstimate(data);
      } catch (error) {
        console.error('Cost estimation error:', error);
        setEstimateError(error.message || 'Failed to estimate cost');
        setCostEstimate(null);
      } finally {
        setEstimateLoading(false);
      }
    }, 500),
    []
  );

  const handlePhoneNumberChange = (e) => {
    const phoneNumber = e.target.value;
    
    // Don't update cost estimate if retry modal is open
    if (retryState.isRetrying) {
      return;
    }
    
    setCostEstimate(null);
    setEstimateError(null);
  
    if (/^\+[1-9]\d{10,14}$/.test(phoneNumber)) {
      estimateCost(phoneNumber);
    }
  };

  // In SetupStep.js
  const startRetryTimer = (delay) => {
    const endTime = Date.now() + delay;
    
    const updateTimer = () => {
      if (!retryState.isRetrying) return;
      
      const remaining = Math.max(0, Math.ceil((endTime - Date.now()) / 1000));
      setRetryState(prev => ({
        ...prev,
        timeRemaining: remaining
      }));

      if (remaining > 0) {
        setTimeout(updateTimer, 1000);
      }
    };

    updateTimer();
  };

  const handleRetry = async (values, isManual = false) => {
    if (isManual && retryState.attempt >= MAX_MANUAL_RETRIES) {
      message.error('Maximum retry attempts reached');
      return;
    }
  
    try {
      // Add jitter to the delay
      const baseDelay = isManual ? 0 : MIN_RETRY_DELAY * Math.pow(RETRY_DELAY_MULTIPLIER, retryState.attempt);
      const jitter = Math.random() * (baseDelay * 0.2); // Add ±20% randomness
      const delay = baseDelay + jitter;
      
      if (!isManual) {
        startRetryTimer(delay);
        await new Promise(resolve => setTimeout(resolve, delay));
      }

      const result = await callsAPI.makeCall(values.phoneNumber, values.prompt, true);
      setRetryState(prev => ({
        ...prev,
        isRetrying: false
      }));
      onSubmit(result);
    } catch (error) {
      // Add increasing delays even for manual retries
      if (error.isSystemBusy) {
        const nextAttempt = retryState.attempt + 1;
        const minWait = isManual ? 2000 * nextAttempt : 0; // Add growing delay even for manual retries
        
        setRetryState(prev => ({
          ...prev,
          isRetrying: true,
          attempt: nextAttempt,
          currentCalls: error.currentCalls || 1,
          minWaitTime: minWait // Add this to state
        }));
  
        if (!isManual && nextAttempt < MAX_AUTO_RETRIES) {
          handleRetry(values);
        }
      } else {
        setSubmitError(error.message);
        setRetryState(prev => ({ ...prev, isRetrying: false }));
      }
    }
  };

  const handleSubmit = async (values) => {
    try {
      setSubmitError(null);
      setIsSubmitting(true);
      setCurrentFormValues(values);
      const result = await callsAPI.makeCall(values.phoneNumber, values.prompt);
      localStorage.removeItem('callFormData'); // Add this line
      onSubmit(result);
    } catch (error) {
      console.log('Call error:', error);
      
      if (error.isSystemBusy) {
        console.log('System busy, starting retry flow:', error);
        setRetryState(prev => ({
          ...prev,
          isRetrying: true,
          attempt: 0,
          currentCalls: error.currentCalls || 1
        }));
        handleRetry(values);
        return;
      }
      
      setSubmitError(error.message || 'An unexpected error occurred');
    } finally {
      setIsSubmitting(false);
    }
  };
  const CostEstimate = ({ data, loading, error }) => {
    if (loading) {
      return (
        <div className="cost-estimate-loading">
          <Spin size="small" />
          <Text type="secondary">Calculating cost...</Text>
        </div>
      );
    }

    if (error) {
      return (
        <Alert
          message="Cost Estimation Error"
          description={error}
          type="error"
          showIcon
          icon={<WarningOutlined />}
          className="cost-estimate-error"
        />
      );
    }

    if (!data) return null;

    const estimatedMinutes = getEstimatedDuration();
    const hasInsufficientCredits = estimatedMinutes < 5;

    return (
      <div className="cost-estimate">
        <div className="cost-estimate-header">
          <Title level={5} className="total-cost">
            ${data.perMinute.total.toFixed(2)}
            <Text type="secondary" className="per-minute">
              /min
            </Text>
          </Title>
        </div>
        <div className="cost-breakdown">
          <div className="cost-item">
            <Text type="secondary">Call Type:</Text>
            <Text strong>{data.rateType}</Text>
          </div>
          <div className="cost-details">
            <div className="cost-row">
              <Text type="secondary">Twilio Rate:</Text>
              <Text>${data.perMinute.twilioRate.toFixed(3)}</Text>
            </div>
            <div className="cost-row">
              <Text type="secondary">OpenAI Rate:</Text>
              <Text>${data.perMinute.openAIRate.toFixed(3)}</Text>
            </div>
            <div className="cost-row">
              <Text type="secondary">Platform Fee:</Text>
              <Text>${data.perMinute.platformFee.toFixed(3)}</Text>
            </div>
          </div>
        </div>
        {balance !== null && (
          <div className="credits-status">
            <div className="balance-info">
              <Text type="secondary">Your Balance:</Text>
              <Text strong>${balance.toFixed(2)}</Text>
            </div>
            {estimatedMinutes !== null && (
              <div className="duration-estimate">
                <Text type="secondary">Estimated talk time:</Text>
                <Text strong>{estimatedMinutes} minutes</Text>
              </div>
            )}
            {hasInsufficientCredits && (
              <Alert
                message="Low Credits"
                description={
                  <span>
                    You have credits for less than 5 minutes of call time.{' '}
                    <Link onClick={() => navigate('/credits')}>Add more credits</Link>
                  </span>
                }
                type="warning"
                showIcon
                className="low-credits-alert"
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const renderRetryModal = () => (
    <Modal
      title="System at Capacity"
      open={retryState.isRetrying}
      footer={null}
      closable={false}
      maskClosable={false}
      centered
      width={400}
      maskStyle={{ backgroundColor: 'rgba(0, 0, 0, 0.45)', backdropFilter: 'blur(2px)' }}
    >
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
      <Alert
        message={`Active Calls: ${retryState.currentCalls}`}
        description={
          <>
            System is currently at maximum capacity. 
            {retryState.attempt > 0 && (
              <Text type="secondary">
                <br/>Wait time increasing to reduce server load.
              </Text>
            )}
          </>
        }
        type="info"
        showIcon
      />

      {retryState.attempt < MAX_AUTO_RETRIES ? (
          <>
            <div style={{ textAlign: 'center', padding: '20px 0' }}>
              <Text>
                Auto-retrying every {MIN_RETRY_DELAY/1000} seconds
              </Text>
              <br />
              <Text type="secondary">
                Attempt {retryState.attempt + 1} of {MAX_AUTO_RETRIES}
              </Text>
            </div>
            <Button danger block onClick={() => {
              setRetryState(prev => ({ ...prev, isRetrying: false }));
            }}>
              Cancel
            </Button>
          </>
        ) : (
          <>
          <Alert 
            message="Automatic retries exhausted"
            description="Manual retries available with increasing delays to prevent server overload"
            type="warning"
            showIcon
          />
          <Row gutter={8}>
            <Col span={12}>
              <Button block onClick={() => {
                setRetryState(prev => ({ ...prev, isRetrying: false }));
              }}>
                Cancel
              </Button>
            </Col>
            <Col span={12}>
              <Button 
                type="primary" 
                block
                onClick={() => handleRetry(currentFormValues, true)}
                disabled={retryState.minWaitTime > 0}
              >
                {retryState.minWaitTime > 0 
                  ? `Wait ${Math.ceil(retryState.minWaitTime/1000)}s` 
                  : 'Retry Manually'}
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Space>
  </Modal>
);

  return (
    <div style={{ pointerEvents: retryState.isRetrying ? 'none' : 'auto' }}>
      <Card className="credit-info-card">
        <div className="credit-info-content">
          <div className="credit-info-text-section">
            <Text className="credit-title">First Time Here?</Text>
            <Text className="credit-info-text">
              Purchase credits to start making AI-powered calls. Credits start at just $2.
            </Text>
          </div>
          <Button 
            type="primary"
            size="large"
            icon={<CreditCardOutlined />}
            onClick={() => navigate('/credits')}
            className="get-credits-button"
          >
            Get Credits
          </Button>
        </div>
      </Card>

      <Alert
        message={
          <span>
            New here?{' '}
            <Link onClick={() => setIsGuideModalOpen(true)} className="learn-more-link">
              Learn more about how it works
            </Link>
          </span>
        }
        type="info"
        showIcon
        icon={<InfoCircleOutlined />}
        className="learn-more-alert"
      />

      <VoxiGuideModal 
        open={isGuideModalOpen}
        onClose={() => setIsGuideModalOpen(false)}
      />

      {renderRetryModal()}

      {submitError && (
        <Alert
          message="Error Starting Call"
          description={submitError}
          type="error"
          showIcon
          className="submit-error-alert"
          closable
          onClose={() => setSubmitError(null)}
        />
      )}

      <Card className="setup-card">
      <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          className="call-form"
          onFieldsChange={(_, allFields) => {
            const formData = form.getFieldsValue();
            localStorage.setItem('callFormData', JSON.stringify(formData));
          }}
        >
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            rules={[
              { required: true, message: 'Please enter a phone number' },
              {
                pattern: /^\+[1-9]\d{10,14}$/,
                message: 'Please enter a valid phone number in E.164 format (e.g., +1234567890)'
              }
            ]}
            help="Enter number in international format (e.g., +1234567890)"
          >
            <Input
              prefix={<PhoneOutlined className="site-form-item-icon" />}
              placeholder="+1234567890"
              onChange={handlePhoneNumberChange}
              size="large"
              disabled={isSubmitting || retryState.isRetrying}
            />
          </Form.Item>

          <CostEstimate 
            data={costEstimate} 
            loading={estimateLoading && !retryState.isRetrying}  // Don't show loading when retry modal is open
            error={estimateError}
          />

          <Form.Item
            label="Call Instructions"
            name="prompt"
            rules={[
              { required: true, message: 'Please enter call instructions' },
              { min: 10, message: 'Instructions must be at least 10 characters' }
            ]}
            className="prompt-input"
          >
            <TextArea
              rows={4}
              placeholder="Enter detailed instructions for the AI to follow during the call..."
              size="large"
              disabled={isSubmitting || retryState.isRetrying}
            />
          </Form.Item>

          <Form.Item className="submit-button">
            <Button 
              type="primary" 
              htmlType="submit"
              size="large"
              loading={isLoading || isSubmitting}
              disabled={
                estimateLoading || 
                !costEstimate || 
                isSubmitting || 
                !balance || 
                balance <= 0 ||
                retryState.isRetrying
              }
              block
            >
              {isSubmitting ? 'Initiating Call...' : 'Start Call'}
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default SetupStep;