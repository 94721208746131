import React from 'react';
import { Card, Typography, Button, Alert, Space } from 'antd';
import { MailOutlined, ReloadOutlined } from '@ant-design/icons';
import { useAuth } from '../../contexts/AuthContext';

const { Title, Text } = Typography;

const EmailVerification = ({ email, onBackToSignIn }) => {
  const { resendVerificationEmail, verificationEmailSent } = useAuth();

  const handleResendEmail = async () => {
    try {
      await resendVerificationEmail();
    } catch (error) {
      // Error is handled by AuthContext
    }
  };

  return (
    <Card className="auth-verification-card">
      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <div className="verification-header">
          <MailOutlined className="verification-icon" />
          <Title level={3}>Verify your email</Title>
          <Text className="verification-subtitle">
            We've sent a verification link to:
          </Text>
          <Text strong>{email}</Text>
        </div>

        {verificationEmailSent && (
          <Alert
            message="Verification email sent!"
            description="Please check your inbox and click the verification link."
            type="success"
            showIcon
          />
        )}

        <div className="verification-instructions">
          <Text>
            Please check your email and click the verification link to complete your registration.
            If you don't see the email, check your spam folder.
          </Text>
        </div>

        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
          <Button 
            icon={<ReloadOutlined />}
            onClick={handleResendEmail}
            block
          >
            Resend verification email
          </Button>
          
          <Button 
            type="link" 
            onClick={onBackToSignIn}
            block
          >
            Back to sign in
          </Button>
        </Space>
      </Space>
    </Card>
  );
};

export default EmailVerification;