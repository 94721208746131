import React, { useState, useEffect, useMemo } from 'react';
import { 
  Table, 
  Typography, 
  Space, 
  Button, 
  Modal, 
  Descriptions, 
  Tag, 
  Input,
  DatePicker,
  Alert,
  Tabs,
  Card,
  Divider,
  Tooltip
} from 'antd';
import { 
  PhoneOutlined, 
  ClockCircleOutlined, 
  MessageOutlined, 
  DollarOutlined,
  SearchOutlined,
  CalendarOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { callsAPI } from '../../services/api';
import { getCallOutcome } from '../../utils/callOutcomeUtils';

const { Title, Text, Paragraph } = Typography;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const CallHistory = () => {
  const [calls, setCalls] = useState([]);
  const [selectedCall, setSelectedCall] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [dateRange, setDateRange] = useState(null);

  const fetchCalls = async () => {
    try {
      setLoading(true);
      setError(null);
      const data = await callsAPI.getCallHistory();
      if (data.success && Array.isArray(data.history)) {
        console.log('Fetched call history:', data.history);
        setCalls(data.history);
      } else {
        throw new Error('Invalid data format received');
      }
    } catch (error) {
      console.error('Error fetching calls:', error);
      setError('Failed to load call history. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCalls();
  }, []);

  const parseSummaryText = (summaryText) => {
    if (!summaryText) return null;

    const structured = {
      mainObjective: '',
      outcome: '',
      keyPoints: [],
      nextSteps: []
    };

    const parts = summaryText.split(/\d+\.\s+/);
    if (parts[0] === '') parts.shift();

    parts.forEach(part => {
      if (part.startsWith('MAIN OBJECTIVE:')) {
        structured.mainObjective = part.replace('MAIN OBJECTIVE:', '').trim();
      }
      else if (part.startsWith('OUTCOME:')) {
        structured.outcome = part.replace('OUTCOME:', '').trim();
      }
      else if (part.startsWith('KEY POINTS:')) {
        const points = part.replace('KEY POINTS:', '')
          .split('-')
          .map(point => point.trim())
          .filter(point => point.length > 0);
        structured.keyPoints = points;
      }
      else if (part.startsWith('NEXT STEPS:')) {
        const steps = part.replace('NEXT STEPS:', '')
          .split('.')
          .map(step => step.trim())
          .filter(step => step.length > 0);
        structured.nextSteps = steps;
      }
    });

    return structured;
  };

  const getStatusTag = (record) => {
    const { status, confidence } = getCallOutcome(
      record.conversation,
      { structuredSummary: record.structuredSummary }
    );

    const getTagColor = (status, confidence) => {
      switch (status) {
        case 'completed':
          return 'success';
        case 'failed':
          return 'error';
        case 'incomplete':
          return 'warning';
        default:
          return 'default';
      }
    };

    const getTagText = (status, confidence) => {
      const text = status.charAt(0).toUpperCase() + status.slice(1);
      return confidence !== 'high' ? (
        <Space>
          {text}
          <Tooltip title={`Confidence: ${confidence}`}>
            <InfoCircleOutlined style={{ fontSize: '12px' }} />
          </Tooltip>
        </Space>
      ) : text;
    };

    return (
      <Tag color={getTagColor(status, confidence)}>
        {getTagText(status, confidence)}
      </Tag>
    );
};

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    });
  };

  const formatDuration = (seconds) => {
    if (!seconds) return '0:00';
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, '0')}`;
  };

  const formatCost = (cost) => {
    if (!cost && cost !== 0) return '-';
    return `$${Number(cost).toFixed(2)}`;
  };

  const filteredCalls = useMemo(() => {
    return calls.filter(call => {
      const matchesSearch = searchText ? (
        call.toNumber?.toLowerCase().includes(searchText.toLowerCase()) ||
        call.prompt?.toLowerCase().includes(searchText.toLowerCase())
      ) : true;

      const matchesDate = dateRange ? (
        new Date(call.timestamp) >= dateRange[0] &&
        new Date(call.timestamp) <= dateRange[1]
      ) : true;

      return matchesSearch && matchesDate;
    });
  }, [calls, searchText, dateRange]);

  const columns = [
    {
      title: 'Date',
      dataIndex: 'timestamp',
      key: 'timestamp',
      sorter: (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
      defaultSortOrder: 'descend',
      render: (text) => (
        <Space>
          <CalendarOutlined />
          <Text>{formatDate(text)}</Text>
        </Space>
      )
    },
    {
      title: 'Phone Number',
      dataIndex: 'toNumber',
      key: 'toNumber',
      render: (text) => (
        <Space>
          <PhoneOutlined />
          <Text>{text}</Text>
        </Space>
      )
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => (a.duration || 0) - (b.duration || 0),
      render: (duration) => (
        <Space>
          <ClockCircleOutlined />
          <Text>{formatDuration(duration)}</Text>
        </Space>
      )
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      key: 'cost',
      sorter: (a, b) => (a.cost || 0) - (b.cost || 0),
      render: (cost) => (
        <Space>
          <DollarOutlined />
          <Text>{formatCost(cost)}</Text>
        </Space>
      )
    },
    {
      title: 'Status',
      key: 'status',
      render: (_, record) => getStatusTag(record)
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Button 
          type="link"
          onClick={() => setSelectedCall(record)}
        >
          View Details
        </Button>
      )
    }
  ];

  const getResultMessage = (summaryData) => {
    const resultPoint = summaryData?.keyPoints?.find(point => 
      point.toLowerCase().includes('available') || 
      point.toLowerCase().includes('found') ||
      point.toLowerCase().includes('result')
    );
    return resultPoint || summaryData?.outcome;
  };

  const renderCallDetails = () => {
    if (!selectedCall) return null;

    const summaryData = selectedCall.structuredSummary || parseSummaryText(selectedCall.summary);
    // Replace isCallSuccessful with getCallOutcome
    const { status } = getCallOutcome(selectedCall.conversation, { structuredSummary: summaryData });
    const isSuccessful = status === 'completed';

    return (
      <div className="call-details-content">
        <Descriptions bordered>
          <Descriptions.Item label="Date" span={2}>
            {formatDate(selectedCall.timestamp)}
          </Descriptions.Item>
          <Descriptions.Item label="Duration">
            {formatDuration(selectedCall.duration)}
          </Descriptions.Item>
          <Descriptions.Item label="Phone Number" span={2}>
            {selectedCall.toNumber}
          </Descriptions.Item>
          <Descriptions.Item label="Cost">
            {formatCost(selectedCall.cost)}
          </Descriptions.Item>
        </Descriptions>

        <Tabs defaultActiveKey="summary" className="details-tabs">
          <TabPane tab="Summary" key="summary">
            <Space direction="vertical" size="large" style={{ width: '100%' }}>
              <Alert
                message={isSuccessful ? "Task Completed Successfully" : "Task Incomplete"}
                type={isSuccessful ? "success" : "warning"}
                showIcon
                style={{ alignItems: 'center', display: 'flex' }}
              />

              {getResultMessage(summaryData) && (
                <Alert
                  message="Result"
                  description={<Text strong>{getResultMessage(summaryData)}</Text>}
                  type="info"
                  showIcon
                />
              )}

              {/* Rest of the component stays exactly the same */}
              <Card title="Objective" className="detail-card">
                <Paragraph>
                  {summaryData?.mainObjective}
                </Paragraph>
              </Card>

              {summaryData?.keyPoints?.length > 0 && (
                <Card title="Key Points" className="detail-card">
                  <ul className="points-list">
                    {summaryData.keyPoints.map((point, index) => (
                      <li key={index}>{point}</li>
                    ))}
                  </ul>
                </Card>
              )}

              {summaryData?.nextSteps?.length > 0 && (
                <Card title="Next Steps" className="detail-card">
                  <ul className="points-list">
                    {summaryData.nextSteps.map((step, index) => (
                      <li key={index}>{step}</li>
                    ))}
                  </ul>
                </Card>
              )}
            </Space>
          </TabPane>

          <TabPane tab="Conversation" key="conversation">
  <Card className="detail-card">
    <div className="prompt-section">
      <Text type="secondary">Original Prompt</Text>
      <Paragraph strong style={{ marginTop: 8, whiteSpace: 'pre-wrap', wordBreak: 'break-word' }}>
        {selectedCall.prompt || 'No prompt available'}
      </Paragraph>
    </div>
    
    <Divider />
    
    {selectedCall.conversation ? (
      <div className="conversation-section">
        <code className="conversation-text">
          {selectedCall.conversation}
        </code>
      </div>
    ) : (
      <Text type="secondary">No conversation transcript available</Text>
    )}
  </Card>
</TabPane>
        </Tabs>
      </div>
    );
  };

  return (
    <div className="call-history-container">
      <Card className="filters-card">
        <Space size="middle" className="filters-space">
          <RangePicker
            onChange={setDateRange}
            placeholder={['Start Date', 'End Date']}
          />
          <Input
            placeholder="Search phone or prompt..."
            prefix={<SearchOutlined />}
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            style={{ width: 250 }}
            allowClear
          />
        </Space>
      </Card>

      <Card className="table-card">
        {error ? (
          <Alert
            message="Error"
            description={error}
            type="error"
            showIcon
            action={
              <Button size="small" type="primary" onClick={fetchCalls}>
                Try Again
              </Button>
            }
          />
        ) : (
          <Table
            loading={loading}
            columns={columns}
            dataSource={filteredCalls}
            rowKey="callSid"
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} calls`,
              pageSizeOptions: ['10', '20', '50', '100']
            }}
          />
        )}
      </Card>

      <Modal
        title="Call Details"
        open={!!selectedCall}
        onCancel={() => setSelectedCall(null)}
        width={800}
        footer={null}
        className="call-details-modal"
      >
        {renderCallDetails()}
      </Modal>
    </div>
  );
};

export default CallHistory;